import React, { useState, useEffect, useContext } from 'react'
import { AiFillEye, AiFillDelete } from 'react-icons/ai'
import { FaEdit } from 'react-icons/fa'
import { useHistory, Link } from 'react-router-dom'
import { BsSearch } from 'react-icons/bs'
import Button from 'react-bootstrap/Button'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

//api
import { getLists } from '../../../api/lists-intranet'
//customHook
import { useQuery } from '../../../utils/customHooks/query'
//context
import { UserContext } from '../../../context/userContext'
//components
import IntranetPagination from '../Pagination'
import CustomInput from '../../../components/customInput'
import CustomTable from '../../../components/table'
import ModalCreateList from './modalCreate/index'
import ModalReadList from './modalRead/index'
import ModalEditList from './modalEdit/index'
import ModalDeleteList from './modalDelete/index'

const Listas = () => {
  const history = useHistory()
  const [lists, setLists] = useState([])
  const searchParams = useQuery()
  const page = searchParams.get('page') || 1
  const pageSize = 10
  const [inputName, setInputName] = useState('')
  const [noResults, setNoResults] = useState(false)
  const [totalPages, setTotalPages] = useState(null)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [dataList, setDataList] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [openModalCreateList, setOpenModalCreateList] = useState(false)
  const [openModalEditList, setOpenModalEditList] = useState(false)
  const [openModalDeleteList, setOpenModalDeleteList] = useState(false)

  const query = {
    nombre: inputName,
    page: page,
    page_size: pageSize,
  }
  const searchQuery = new URLSearchParams(query)
  const queryString = searchQuery.toString()

  const getDataList = async () => {
    try {
      const { data, status } = await getLists(queryString)
      if (status === 200) {
        setLists(data.results)
        setTotalPages(Math.ceil(data.count / pageSize))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDataList()
  }, [])

  useEffect(() => {
    if (inputName !== '') {
      handleSubmit()
    } else {
      getDataList()
    }
  }, [page])

  const handleSubmit = async () => {
    try {
      const { data } = await getLists(queryString)

      if (data.results.length > 0) {
        setLists(data?.results)
        setNoResults(false)
        setTotalPages(Math.ceil(data.count / pageSize))
      } else {
        setLists([])
        setNoResults(true)
      }
    } catch (error) {
      console.log(error)
    }
    history.push(`?${queryString}`)
    setIsActiveSearch(true)
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return

    const items = Array.from(lists)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setLists(items)
  }

  const colsLists = ['Nombre', 'Url']

  const handleValue = (e) => {
    setInputName(e.target.value)
  }

  const handleClear = () => {
    setInputName('')
    history.push('/intranet/listas')
  }

  const openIsModal = (item, id, setOpenModal) => {
    if (item.id === id) {
      setOpenModal(true)
      setDataList(item)
    }
  }

  return (
    <div>
      <div className="d-flex mt-3 justify-content-between">
        <h1>Listas</h1>
        <Button
          variant="secondary"
          type="submit"
          className="height-39 mr-4 "
          onClick={() => setOpenModalCreateList(true)}
        >
          Crear lista
        </Button>
      </div>

      <div className="d-flex justify-content-end m-3">
        <CustomInput
          handleSubmit={(e) => handleSubmit(e)}
          onChange={handleValue}
          inputValue={inputName}
          icon={<BsSearch color="white" />}
          placeholder={'Buscar por nombre'}
          type={'search'}
        />
        <button onClick={handleClear}>Limpiar url</button>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <CustomTable cols={colsLists} noResults={noResults}>
                {lists &&
                  lists.map((list, index) => {
                    return (
                      <Draggable
                        key={list.id}
                        draggableId={list.nombre}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              width: snapshot.isDragging ? '100%' : null,
                              background: snapshot.isDragging
                                ? '#dee2e6'
                                : null,
                              display: snapshot.isDragging ? 'table' : null,
                              ...provided.draggableProps.style,
                            }}
                          >
                            <td className="text-center">{list.nombre}</td>
                            <td className="text-center">{list.url}</td>
                            <td className="text-center">
                              <AiFillEye
                                className="mr-5 cursor-pointer"
                                onClick={() =>
                                  openIsModal(list, list.id, setOpenModal)
                                }
                              />
                              <FaEdit
                                className="cursor-pointer"
                                color="gray"
                                onClick={() =>
                                  openIsModal(
                                    list,
                                    list.id,
                                    setOpenModalEditList
                                  )
                                }
                              />

                              <AiFillDelete
                                className="cursor-pointer ml-5"
                                onClick={() =>
                                  openIsModal(
                                    list,
                                    list.id,
                                    setOpenModalDeleteList
                                  )
                                }
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    )
                  })}
              </CustomTable>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <IntranetPagination
        page={parseInt(page)}
        pages={totalPages}
        isActiveSearch={isActiveSearch}
        queryParams={queryString}
        getData={getLists}
      />
      <ModalCreateList
        getDataList={getDataList}
        openModal={openModalCreateList}
        setOpenModal={setOpenModalCreateList}
      />
      <ModalReadList
        dataList={dataList}
        isModal={openModal}
        setIsModal={setOpenModal}
      />
      <ModalEditList
        dataList={dataList}
        getDataList={getDataList}
        openModal={openModalEditList}
        setOpenModal={setOpenModalEditList}
      />
      <ModalDeleteList
        getDataList={getDataList}
        isOpen={openModalDeleteList}
        setIsOpen={setOpenModalDeleteList}
        url={dataList?.url}
      />
    </div>
  )
}

export default Listas
