import React, { useEffect, useState } from 'react'

// api
import { getListsHome } from '../../api/lists'

// own components
import ListHome from './listHome'
import SectionLoader from '../../components/loader/SectionLoader'

const ListsHome = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    const handleLoadData = async () => {
      try {
        const response = await getListsHome()
        if (response.status === 200) {
          setData(response.data)
        }
      } catch (error) {
        console.log(error)
      }
    }

    handleLoadData()
  }, [])

  return (
    <>
      {data && data.length > 0 ? (
        data.map((item, i) => <ListHome key={i} data={item} />)
      ) : (
        <SectionLoader />
      )}
    </>
  )
}

export default ListsHome
