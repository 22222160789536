import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'

//utils
import { useQuery } from '../../../../utils/customHooks/query'

//components
import ModalCustom from '../../../../components/modal/index'
import { Col, Form, Row } from 'react-bootstrap'

//api
import {
  createSlider,
  getListProducts,
  searchFilterProductByTitle,
} from '../../../../api/products'

const ModalCreateSlider = ({ setCreateModal, modal, getSliders }) => {
  const searchParams = useQuery()
  const [sliderTipo, setSliderTipo] = useState('')
  const [sliderActivo, setSliderActivo] = useState(false)
  const [sliderProducto, setSliderProducto] = useState('')
  const [sliderImagen, setSliderImagen] = useState(null)
  const [allProducts, setAllProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])

  const params = {
    producto: sliderProducto,
    tipo: sliderTipo,
    estado: sliderActivo,
    portada: sliderImagen,
  }

  useEffect(() => {
    const getProductsList = async () => {
      const { data, status } = await getListProducts()
      console.log(data)
      if (status === 200) {
        setAllProducts(data.results)
      }
    }
    getProductsList()
  }, [])

  const handleSearch = async (query) => {
    setIsLoading(true)
    const { status, data } = await searchFilterProductByTitle(
      `${query}&page=1&page_size=100`
    )
    if (status === 200) {
      setOptions(data.results)
      setIsLoading(false)
    }
  }

  const handleSelectProduct = (e) => {
    setSliderProducto(e[0].id)
  }

  const filterBy = () => true

  const updateSlider = async (e) => {
    e.preventDefault()
    let formData = new FormData()
    for (const key in params) {
      formData.append(key, params[key])
    }
    const { status } = await createSlider(formData)
    if (status === 201) {
      getSliders()
      setCreateModal(false)
    } else {
      setCreateModal(true)
    }
  }

  const handleImageChange = (event) => {
    setSliderImagen(event.target.files[0])
  }

  const handleTipo = (e) => {
    setSliderTipo(e.target.value)
  }

  const handleClose = () => {
    setCreateModal(false)
  }

  const tipos = [
    {
      title: 'Libro',
      value: 1,
    },
    {
      title: 'Cine',
      value: 2,
    },
    {
      title: 'Música',
      value: 3,
    },
  ]

  const size = 'xl'

  const Buttonfooter = () => {
    return (
      <div className="text-center">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cancelar
        </Button>
        <Button variant="secondary" onClick={updateSlider}>
          Aceptar
        </Button>
      </div>
    )
  }

  return (
    <ModalCustom
      show={modal}
      title={<p className="text-center">Crear Elemento Slider</p>}
      size={size}
    >
      <Row className="ml-2 mb-2">
        <Col sm={6}>
          <Row>
            <Form.Group>
              <Form.Label>Elija el tipo</Form.Label>
              <Form.Control
                as="select"
                value={sliderTipo}
                onChange={handleTipo}
              >
                <option value="0">Elija una opción</option>
                {tipos.map((item) => (
                  <option key={item.title} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="w-100">
            <Form.Group>
              <Form.Label>Elegir Producto</Form.Label>
              <AsyncTypeahead
                style={{ width: '30rem' }}
                filterBy={filterBy}
                id="product-search"
                isLoading={isLoading}
                labelKey="titulo"
                minLength={3}
                onSearch={handleSearch}
                onChange={handleSelectProduct}
                options={options}
                placeholder="Buscar producto por titulo"
                renderMenuItemChildren={(option) => (
                  <span>{option.titulo}</span>
                )}
              />
            </Form.Group>
          </Row>
        </Col>
      </Row>
      <Row className="ml-1 mb-2">
        <Col sm={6}>
          <Form.Group controlId="portada" className="mb-3">
            <Form.File
              onChange={handleImageChange}
              label="Portada"
              accept="image/*"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="ml-2 mb-2">
        <Col sm={6}>
          <Form.Check
            type="checkbox"
            id="activo"
            label="Activo"
            onClick={(e) => setSliderActivo(e.target.checked)}
          />
        </Col>
      </Row>
      <Buttonfooter />
    </ModalCustom>
  )
}

export default ModalCreateSlider
