import React from 'react'
import ImageNotFound from '../imageNotFound'
import './itemSearch.scss'
import { checkEmpty } from '../../helpers'
import { Link } from 'react-router-dom'

const ItemSearch = (props) => {
  const truncate = (str) => {
    return str.length > 40 ? str.substring(0, 37) + '...' : str
  }
  const { data } = props
  const urlItem = checkEmpty(data)
    ? data.type === 'autor'
      ? `/${data.type}/${data.url}/`
      : `/productos/${data.type}/${data.url}/`
    : ''

  const boxData = {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: '70px',
  }

  const title = {
    color: '#127CA6',
    fontSize: '11px',
    lineHeight: '1',
    fontFamily: 'sans-serif',
    fontWeight: 'bolder',
    height: '27px',
  }

  const subtitle = {
    color: '#011C40',
    fontSize: '12px',
    opacity: '.7',
    lineHeight: '1',
    fontFamily: 'sans-serif',
    fontWeight: 'bolder',
  }

  return (
    <>
      <Link
        to={urlItem}
        className="contentTileSearch"
        style={{ textDecoration: 'none' }}
      >
        <ImageNotFound
          src={data.cover}
          width="125"
          height="150"
          title={truncate(data.titulo)}
          editorial={data.nombre_editorial}
          type={data.type}
        />
        <div style={boxData}>
          <h6 style={title} data-testid="title">
            {truncate(data.titulo)}
          </h6>
          <h6 style={subtitle} data-testid="subtitle">
            {data.nombre_editorial ? data.nombre_editorial : 'Autor'}
          </h6>
        </div>
      </Link>
    </>
  )
}

export default ItemSearch
