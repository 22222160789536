import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Row, Col, Container, Spinner } from 'react-bootstrap'

// api
import { getElementsList, getDetailList } from '../../api/lists'
import {
  getDetailMateryGeslib,
  getDetailMateryIbic,
  getElementsMateryGeslib,
  getElementsMateryIbic,
} from '../../api/libros'
import {
  getDetailAuthor,
  getProductsByAuthor,
  getDetailEditorial,
  getProductsByEditorial,
} from '../../api/products'

//hoks
import { useIntersectionObserver } from 'react-intersection-observer-hook'
// own components
import ItemList from '../../components/itemList/item'

// own constants
import { COMPANY } from '../../constans'

import { companySelector } from '../../slices/company.js'

import { useSelector } from 'react-redux'
import { convertMetatagName, recoverMetatagName } from '../../utils'

import Head from '../../components/metatags_helmet/metatags'

const List = (props) => {
  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [nextPage, setNextPage] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [metaTitle, setMetaTitle] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const [contentListBottom, { entry }] = useIntersectionObserver()
  const isVisible = entry && entry.isIntersecting
  const { name: nameCompany } = useSelector(companySelector)
  let history = useHistory()

  const partsPathname = props.location.pathname.split('/')
  const type = partsPathname[1]
  const slug = partsPathname[2]

  const getList = async () => {
    let responseDetail
    if (type === 'lista') {
      responseDetail = await getDetailList(slug)
      if (responseDetail.status === 200) {
        setName(responseDetail.data.nombre)
      }
    } else if (type === 'materia') {
      responseDetail = await getDetailMateryGeslib(slug)
      if (responseDetail.status === 200) {
        setName(responseDetail.data.descripcion)
      } else {
        responseDetail = await getDetailMateryIbic(slug)
        if (responseDetail.status === 200) {
          setName(responseDetail.data.descripcion)
        }
      }
    } else if (type === 'autor') {
      responseDetail = await getDetailAuthor(slug)
      if (responseDetail.status === 200) {
        setName(responseDetail.data.nombre_autor)
      }
    } else if (type === 'editorial') {
      responseDetail = await getDetailEditorial(slug)
      if (responseDetail.status === 200) {
        setName(responseDetail.data.nombre_fiscal)
      }
    }
  }

  useEffect(() => {
    const getLoadData = async () => {
      let newData
      if (type == 'lista') {
        setIsLoading(true)
        const { status, data } = await getElementsList(slug, page)
        if (status === 200) {
          if (page === 1) {
            newData = data.results
          } else {
            newData = [...items, ...data.results]
          }
          setItems(newData)
          setNextPage(() => (data.next !== null ? true : false))
          setIsLoading(false)
        }
      } else if (type == 'materia') {
        setIsLoading(true)
        const { status, data } = await getElementsMateryGeslib(slug, page)
        if (status === 200) {
          if (page === 1) {
            newData = data.results
          } else {
            newData = [...items, ...data.results]
          }
          setItems(newData)
          setNextPage(() => (data.next !== null ? true : false))
          setIsLoading(false)
        } else {
          const { status, data } = await getElementsMateryIbic(slug, page)
          if (status === 200) {
            if (page === 1) {
              newData = data.results
            } else {
              newData = [...items, ...data.results]
            }
            setItems(newData)
            setNextPage(() => (data.next !== null ? true : false))
            setIsLoading(false)
          }
        }
      } else if (type == 'autor') {
        setIsLoading(true)
        const { status, data } = await getProductsByAuthor(slug, page)
        if (status === 200) {
          if (page === 1) {
            newData = data.results
          } else {
            newData = [...items, ...data.results]
          }
          setItems(newData)
          setNextPage(() => (data.next !== null ? true : false))
          setIsLoading(false)
        }
      } else if (type == 'editorial') {
        setIsLoading(true)
        const { status, data } = await getProductsByEditorial(slug, page)
        if (status === 200) {
          if (page === 1) {
            newData = data.results
          } else {
            newData = [...items, ...data.results]
          }
          setItems(newData)
          setNextPage(() => (data.next !== null ? true : false))
          setIsLoading(false)
        }
      }
    }
    getLoadData()
  }, [slug, page])

  useEffect(() => {
    const goUp = () => {
      window.scrollTo(0, 0)
    }
    goUp()
    getList()
    setPage(1)
  }, [slug])

  const setTitle = () => {
    if (name.toLowerCase() !== 'merchandising') {
      setMetaTitle(`${recoverMetatagName(name)}`)
      setDescription(
        `Si buscas libros, películas o música sobre ${recoverMetatagName(
          name
        )}, ¡estás de suerte en encontrarnos! Compra en ${nameCompany} a domicilio.`
      )
    } else {
      setMetaTitle(`Merchandising ${nameCompany} | Venta Online de Productos`)
      setDescription(
        `Si eres fan de la variedad de Libros, Películas o Canciones en ${nameCompany} tenemos un amplio catálogo de merchandising que te puede gustar.`
      )
    }
  }

  useEffect(() => {
    setTitle()
  }, [name])

  useEffect(() => {
    // infinite scrolling
    ;(async () => {
      if (isVisible && nextPage) {
        setPage((prevPage) => prevPage + 1)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <Container>
      <Head description={description} title={metaTitle} />
      <Row>
        <Col md={12} className="title">
          <h2 style={{ textTransform: 'capitalize' }}>{name}</h2>
          <span className="divider-title"></span>
        </Col>
        {items.map((item, i) => (
          <Col md={4} lg={3} sm={6} key={i} style={{ marginTop: 5 }}>
            <ItemList key={i} data={item} />
          </Col>
        ))}
      </Row>
      <Col className="d-flex align-items-center justify-content-center mt-5">
        {isLoading && <Spinner animation="border" role="status" size="lg" />}
      </Col>
      <div
        className="content-bottom"
        style={{ height: '20px' }}
        ref={contentListBottom}
      ></div>
    </Container>
  )
}

export default List
