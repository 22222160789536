import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { changePassword } from '../../../api/users'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import delay from 'delay'
import { RiLockPasswordFill } from 'react-icons/ri'

const PasswordModal = (prop) => {
  const [show, setShow] = useState(false)
  const [comparePass, setComparePass] = useState(true)
  const [errorChangePass, setErrorChangePass] = useState('')
  const [successChangePass, setSuccessChangePass] = useState(false)
  const [formChangePass, setFormChangePass] = useState({
    oldPass: '',
    newPass: '',
    repeatPass: '',
  })

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const comparePassword = () => {
    if (formChangePass.newPass !== formChangePass.repeatPass) {
      setComparePass(false)
    } else {
      setComparePass(true)
    }
  }

  const handleChangePass = async (event) => {
    event.preventDefault()
    comparePassword()
    setSuccessChangePass(false)
    const { oldPass, newPass, repeatPass } = formChangePass
    if (oldPass !== '' && newPass !== '' && repeatPass !== '') {
      const rs = await changePassword(formChangePass)
      if (rs.status === 200) {
        setFormChangePass({
          oldPass: '',
          newPass: '',
          repeatPass: '',
        })
        setSuccessChangePass(true)
        await delay(4000)
        setSuccessChangePass(false)
      } else if (rs.status === 400) {
        console.log('called')
        setErrorChangePass(rs.data.msg)
      }
    } else {
      setErrorChangePass('Todos los campos son obligatorios')
    }
  }

  return (
    <>
      <Link
        variant="primary"
        onClick={handleShow}
        to={{ prop }}
        className="text-left w-100 pt-1 mb-1 link-text"
      >
        <RiLockPasswordFill className="mr-3" />
        Cambiar Contraseña
      </Link>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cambiar contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleChangePass}>
            <Form.Group className="mb-3 mt-2" controlId="contraseñaActual">
              <Form.Label>Contraseña Actual</Form.Label>
              <Form.Control
                type="password"
                value={formChangePass.oldPass}
                required={true}
                onChange={(e) =>
                  setFormChangePass({
                    ...formChangePass,
                    oldPass: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3 mt-2" controlId="newPass">
              <Form.Label>Nueva contraseña</Form.Label>
              <Form.Control
                type="password"
                value={formChangePass.newPass}
                required={true}
                onChange={(e) =>
                  setFormChangePass({
                    ...formChangePass,
                    newPass: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3 mt-2" controlId="repPass">
              <Form.Label>Confirmar contraseña</Form.Label>
              <Form.Control
                type="password"
                value={formChangePass.repeatPass}
                required={true}
                onFocus={() => setComparePass(true)}
                onChange={(e) =>
                  setFormChangePass({
                    ...formChangePass,
                    repeatPass: e.target.value,
                  })
                }
              />
            </Form.Group>
            {errorChangePass !== '' ? (
              <Form.Text className="mb-2" style={{ color: 'red' }}>
                {errorChangePass}
              </Form.Text>
            ) : null}
            {successChangePass ? (
              <Form.Text className="mb-2" style={{ color: 'green' }}>
                Su contraseña se ha actualizado correctamente
              </Form.Text>
            ) : null}
            <Button
              variant="secondary"
              type="submit"
              block
              size="lg"
              disabled={!comparePass}
            >
              Cambiar Contraseña
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PasswordModal
