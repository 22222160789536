import React, { useState } from 'react'
import authorImg from '../../assets/img/feather.jpg'

const ImageNotFound = (props) => {
  const [loaded, setLoaded] = useState(false)
  const backContainer = {
    cursor: 'pointer',
    backgroundColor: '#03588C',
    height: `${props.height}px`,
    width: `${props.width}px`,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    boxShadow: 'rgba(21, 22, 26, 0.24) 0px 0px 25px 3px',
  }
  const frameContainer = {
    margin: '10px',
    padding: '3px',
    borderStyle: 'solid',
    borderColor: 'white',
    height: `${props.height - 15}px`,
    width: `${props.width - 25}px`,
    width: '125px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
  const titleStyle = {
    color: 'white',
    fontFamily: 'sans-serif',
    fontWeight: 'bolder',
    fontSize: '10px',
    height: '75px',
    width: '100px',
  }
  const editorialStyle = {
    color: 'white',
    fontFamily: 'sans-serif',
    fontWeight: 'bolder',
    fontSize: '10px',
  }
  return (
    <>
      {!loaded ? (
        <div style={backContainer} data-testid="back-container">
          <div style={frameContainer} data-testid="frame-container">
            <span style={titleStyle} data-testid="title">
              {props.title}
            </span>
            <span style={editorialStyle} data-testid="editorial">
              {props.editorial}
            </span>
          </div>
        </div>
      ) : null}
      <img
        src={props.type !== 'autor' ? props.src : authorImg}
        style={
          (!loaded ? { visibility: 'hidden' } : {},
          { 'border-radius': '4px' },
          { cursor: 'pointer' })
        }
        onLoad={() => setLoaded(true)}
        height={!loaded ? 0 : props.height}
        width={!loaded ? 0 : props.width}
        alt="Image Not Found"
      />
    </>
  )
}

export default ImageNotFound
