import {
  methodPut,
  methodGet,
  methodDelete,
  methodPost,
  methodPostForm,
} from './methods'
import { API_INFO } from '../constans'

export const getListAuthors = async (query) => {
  const url = API_INFO + `products/authors/?${query}`
  return methodGet(url)
}

export const editNameAuthor = async (url, params) => {
  const completeUrl = API_INFO + `products/authors/${url}/`
  return methodPut(completeUrl, params)
}

export const createAuthor = async (params) => {
  const completeUrl = API_INFO + `products/authors/`
  return methodPost(completeUrl, params)
}

export const deleteAuthor = async (url) => {
  const urlComplete = API_INFO + `products/authors/${url}/`
  return methodDelete(urlComplete)
}
