import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Select from 'react-select'

// apis
import { getCategories, getGallery } from '../../api/company'

// own components
import Tile from './tile'
import Head from '../../components/metatags_helmet/metatags'

import '../../assets/css/gallery.scss'

const Gallery = (props) => {
  const [allImages, setAllImages] = useState([])
  const [images, setImages] = useState([])
  const [options, setOptions] = useState([])
  const [category, setCategory] = useState('')
  const title = 'Galería'

  useEffect(() => {
    async function handelCategories() {
      const rs = await getCategories()
      if (rs.status === 200) {
        let o = []
        o.push({ value: '', label: 'Todas las categorias' })
        rs.data.map((item) => o.push({ value: item, label: item }))
        setOptions(o.slice())
      }
    }
    async function handleGallery() {
      const rs = await getGallery()
      if (rs.status === 200) {
        setImages(rs.data)
        setAllImages(rs.data)
        const galleryImgs = rs.data.map(function (x) {
          return x.image
        })
        galleryImgs.forEach((element) => preloadImages(element))
      }
    }
    function preloadImages(url) {
      let img = new Image()
      img.src = url
    }
    handelCategories()
    handleGallery()
  }, [])

  useEffect(() => {
    if (category === 'Todas las categorias' || category === '') {
      setImages(allImages)
    } else {
      const data = allImages.filter((item) => item.category === category)
      setImages(data)
    }
  }, [category, allImages])

  return (
    <div className="container margin-top-md margin-bottom-md">
      <Head title={title} noIndex={true} />
      <Row lg={3}>
        <Col className="margin-bottom-md">
          <div className="galleryCategories">
            <span className="titleSelect">Categorias</span>
            <div className="searchGallery">
              <Select
                placeholder={'Todas las categorias'}
                options={options}
                onChange={(e) => setCategory(e.label)}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="contentTiles">
        <Col>
          <div className="tiles">
            {images.map((d, i) => {
              return <Tile key={i} data={d} index={i} images={images} />
            })}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Gallery
