import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'

// own components
import MenuMainIntranet from './header/index.js'
import { userSelector } from '../../slices/user.js'
import { companySelector } from '../../slices/company.js'
import AdminSideMenu from './sidebar/index.js'
import Main from './main/index.js'

const Intranet = (props) => {
  const { brandLogo } = useSelector(companySelector)
  const { isLogged } = useSelector(userSelector)

  return (
    <>
      <MenuMainIntranet brandLogo={brandLogo} isLogged={isLogged} />
      <main className="margin-md">
        <Row className="m-0">
          <Col lg={2} className="border px-0 overflow-hidden">
            <AdminSideMenu />
          </Col>
          <Col lg={10} className="border min-vh-100">
            <Main />
          </Col>
        </Row>
      </main>
    </>
  )
}

export default Intranet
