import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

//icons
import { FaEdit } from 'react-icons/fa'
import { AiOutlineCheck } from 'react-icons/ai'
import { BsSearch } from 'react-icons/bs'
import { TiInfoLarge } from 'react-icons/ti'

//api
import { getListProducts, searchFilterProduct } from '../../../api/products'

//custom hook
import { useQuery } from '../../../utils/customHooks/query'

//components
import CustomInput from '../../../components/customInput'
import IntranetPagination from '../Pagination'
import CustomTable from '../../../components/table'
import ModalEditProduct from './modalEdit'

const Productos = () => {
  const history = useHistory()
  const [listsName, setListsName] = useState([])
  const searchParams = useQuery()

  const page = searchParams.get('page') || 1
  const pageSize = 10
  const [inputValue, setInputValue] = useState('')
  const [noResults, setNoResults] = useState(false)
  const [totalPages, setTotalPages] = useState(null)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [dataProduct, setDataProduct] = useState({})
  const [openModal, setOpenModal] = useState(false)

  const query = {
    titulo: inputValue,
    page: page,
    page_size: pageSize,
  }

  const searchQuery = new URLSearchParams(query)
  const queryString = searchQuery.toString()

  const getProductsList = async () => {
    const { data, status } = await getListProducts(queryString)
    const { results } = data
    if (status === 200) {
      setListsName(results)
      setTotalPages(Math.ceil(data.count / pageSize))
    }
  }

  const handleValue = (e) => {
    setInputValue(e.target.value)
  }

  const handleSubmit = async () => {
    try {
      const { data } = await searchFilterProduct(query)
      if (data.results.length > 0) {
        setListsName(data?.results)
        setNoResults(false)
        setTotalPages(Math.ceil(data.count / pageSize))
      } else {
        setListsName([])
        setNoResults(true)
      }
    } catch (error) {}
    history.push(`?${queryString}`)
    setIsActiveSearch(true)
  }

  useEffect(() => {
    getProductsList()
  }, [])

  useEffect(() => {
    if (inputValue !== '') {
      handleSubmit()
    } else {
      getProductsList()
    }
  }, [page])

  const handleClear = () => {
    setInputValue('')
    setNoResults(false)
    history.push('/intranet/productos')
  }

  const openIsModal = (item, id, setOpenModal) => {
    if (item.id === id) {
      setOpenModal(true)
      setDataProduct(item)
    }
  }

  const colsEditorials = [
    'Nombre',
    'Publicado',
    'Disponible',
    'Editar',
    'Publicar',
    'Distribuidores',
  ]

  return (
    <div>
      <h1>Productos</h1>
      <div className="d-flex justify-content-end m-3">
        <CustomInput
          handleSubmit={handleSubmit}
          onChange={(e) => handleValue(e)}
          inputValue={inputValue}
          placeholder="Buscar producto por nombre"
          icon={<BsSearch color="white" />}
          type="search"
        />
        <button onClick={handleClear}>Limpiar url</button>
      </div>
      <CustomTable cols={colsEditorials} noResults={noResults}>
        {listsName &&
          listsName.map((listName) => {
            return (
              <tr key={listName.id}>
                <td className="text-center">{listName.titulo}</td>
                <td className="text-center">
                  {listName.fecha_public !== '' ? <p>SI</p> : <p>NO</p>}
                </td>
                <td className="text-center">
                  {listName?.available?.status ? <p>SI</p> : <p>NO</p>}
                </td>
                <td className="text-center">
                  <FaEdit
                    className="cursor-pointer"
                    onClick={() =>
                      openIsModal(listName, listName.id, setOpenModal)
                    }
                  />
                </td>
                <td className="text-center">
                  <AiOutlineCheck className="cursor-pointer" />
                </td>
                <td className="text-center">
                  <TiInfoLarge className="cursor-pointer" />
                </td>
              </tr>
            )
          })}
      </CustomTable>
      <ModalEditProduct
        dataProduct={dataProduct}
        isModal={openModal}
        setIsModal={setOpenModal}
        getProductsList={getProductsList}
      />
      <IntranetPagination
        page={parseInt(page)}
        pages={totalPages}
        isActiveSearch={isActiveSearch}
        queryParams={queryString}
      />
    </div>
  )
}
export default Productos
