import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const CustomMultipleInput = ({ onSubmit, children, className }) => {
  return (
    <Form onSubmit={onSubmit} className={className}>
      {children}
    </Form>
  )
}

export default CustomMultipleInput
