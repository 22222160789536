import { createSlice } from '@reduxjs/toolkit'
import { getDetailProduct } from '../api/products'

export const initialState = {
  items: [],
  countItems: 0,
  subtotal: 0.0,
  iva: 0.0,
  total: 0.0,
  shippingCosts: 0.0,
  lastAddedItem: null,
  lastRemovedItem: null,
  billingAddress: null,
  shippingAddress: null,
  isNewShippingAddress: false,
  storePickup: false,
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action) => {
      const product = action.payload
      const itemExists = state.items.find((item) => product.id === item.id)
      if (itemExists) {
        itemExists.quantity += 1
      } else {
        const newProduct = { ...product, ...{ quantity: 1 } }
        state.items.push(newProduct)
      }
      const iva_porc = product.type === 'libro' ? 4 : 21
      const pvp = parseFloat(product.precio_venta_publico)
      const pc = ((pvp * 100) / (100 + iva_porc)).toFixed(2)
      const subtotal = parseFloat(state.subtotal) + parseFloat(pc)
      const iva = parseFloat(state.iva) + parseFloat(pvp) - parseFloat(pc)
      const total = parseFloat(state.total) + pvp

      state.countItems += 1
      state.shippingCosts = total.toFixed(2) >= 50 ? 0.0 : 3.2
      state.iva = iva.toFixed(2)
      state.subtotal = subtotal.toFixed(2)
      state.total = total.toFixed(2)
      state.lastAddedItem = action.payload.titulo
      state.isIncrease = true
    },
    removeItem: (state, action) => {
      const product = action.payload
      state.items = state.items.filter((item) => product.id !== item.id)
      const iva_porc = product.type === 'libro' ? 4 : 21
      const pvp = parseFloat(product.precio_venta_publico)
      const pc = ((pvp * 100) / (100 + iva_porc)).toFixed(2)
      const subtotal =
        parseFloat(state.subtotal) - parseFloat(pc) * product.quantity
      const iva =
        parseFloat(state.iva) -
        (parseFloat(pvp) - parseFloat(pc)) * product.quantity
      const total = parseFloat(state.total) - pvp * parseFloat(product.quantity)

      state.shippingCosts = total.toFixed(2) >= 50 ? 0.0 : 3.2
      state.isIncrease = false
      state.countItems -= product.quantity
      state.iva = iva.toFixed(2)
      state.subtotal = subtotal.toFixed(2)
      state.total = total.toFixed(2)
      state.lastRemovedItem = action.payload.titulo
    },
    increaseQuantity: (state, action) => {
      const id = action.payload
      const itemExists = state.items.find((item) => id === item.id)
      const iva_porc = itemExists.type === 'libro' ? 4 : 21
      const pvp = parseFloat(itemExists.precio_venta_publico)
      const pc = ((pvp * 100) / (100 + iva_porc)).toFixed(2)
      const subtotal = parseFloat(state.subtotal) + parseFloat(pc)
      const iva = parseFloat(state.iva) + parseFloat(pvp) - parseFloat(pc)
      const total = parseFloat(state.total) + pvp

      itemExists.quantity += 1
      state.shippingCosts = total.toFixed(2) >= 50 ? 0.0 : 3.2
      state.countItems += 1
      state.iva = iva.toFixed(2)
      state.subtotal = subtotal.toFixed(2)
      state.total = total.toFixed(2)
      state.isIncrease = true
      state.lastAddedItem = itemExists.titulo
    },

    decreaseQuantity: (state, action) => {
      const id = action.payload
      const itemExists = state.items.find((item) => id === item.id)
      if (itemExists.quantity === 1) {
        state.items = state.items.filter((item) => id !== item.id)
      } else {
        itemExists.quantity -= 1
      }
      const iva_porc = itemExists.type === 'libro' ? 4 : 21
      const pvp = parseFloat(itemExists.precio_venta_publico)
      const pc = ((pvp * 100) / (100 + iva_porc)).toFixed(2)
      const subtotal = parseFloat(state.subtotal) - parseFloat(pc)
      const iva = parseFloat(state.iva) - (parseFloat(pvp) - parseFloat(pc))
      const total = parseFloat(state.total) - pvp

      state.shippingCosts = total.toFixed(2) >= 50 ? 0.0 : 3.2
      state.countItems -= 1
      state.iva = iva.toFixed(2)
      state.subtotal = subtotal.toFixed(2)
      state.total = total.toFixed(2)
      state.isIncrease = false
      state.lastRemovedItem = itemExists.titulo
    },
    clear: (state, action) => {
      state.items = initialState.items
      state.countItems = initialState.countItems
      state.subtotal = initialState.subtotal
      state.iva = initialState.iva
      state.total = initialState.total
      state.shippingCosts = initialState.shippingCosts
      state.isNewShippingAddress = false
      state.storePickup = false
    },
    clearShippingData: (state) => {
      state.billingAddress = null
      state.shippingAddress = null
    },
    shippingFree: (state, action) => {
      state.shippingCosts = state.total >= 50 || action.payload ? 0.0 : 3.2
    },
    setBillingAddress: (state, action) => {
      state.billingAddress = action.payload
    },
    setShippingAddress: (state, action) => {
      state.shippingAddress = action.payload
    },
    setIsNewShippingAddress: (state, action) => {
      state.isNewShippingAddress = action.payload
    },
    setStorePickup: (state, action) => {
      state.storePickup = action.payload
    },
  },
})

export const {
  addItem,
  removeItem,
  increaseQuantity,
  decreaseQuantity,
  clear,
  shippingFree,
  setBillingAddress,
  setShippingAddress,
  setIsNewShippingAddress,
  setStorePickup,
  clearShippingData,
} = cartSlice.actions
export const cartSelector = (state) => state.cart
export default cartSlice.reducer
