import {
  methodDelete,
  methodGet,
  methodGetExport,
  methodPost,
  methodPostForm,
  methodPut,
} from './methods'
import { API_INFO } from '../constans'

export async function addItemCart(payload) {
  const url = API_INFO + 'ecommerce/carts/add_item/'
  return methodPost(url, payload)
}

export async function removeItemCart(payload) {
  const url = API_INFO + 'ecommerce/carts/remove_item/'
  return methodPost(url, payload)
}

export async function decreaseQuantityItemCart(payload) {
  const url = API_INFO + 'ecommerce/carts/decrease_quantity_item/'
  return methodPost(url, payload)
}

export async function getCart() {
  const url = API_INFO + 'ecommerce/carts/get_active/'
  return methodGet(url, {})
}

export async function settingStorePickup(payload) {
  const url = API_INFO + 'ecommerce/carts/setting_store_pickup/'
  return methodPost(url, payload)
}

export async function createOrder(payload) {
  const url = API_INFO + 'storehouse/orders/'
  return methodPost(url, payload)
}

export async function getInfoRedsys() {
  const url = API_INFO + 'ecommerce/carts/get_info_redsys/'
  return methodGet(url, {})
}

export async function errorPayment() {
  const url = API_INFO + 'ecommerce/carts/error_payment/'
  return methodPost(url, {})
}

export const getBudgetExcel = async (id) => {
  const urlComplete = API_INFO + `ecommerce/budgets/${id}/export_excel/`
  return methodGetExport(urlComplete)
}

export const saveBudget = async (params) => {
  const urlComplete = API_INFO + `ecommerce/budgets/`
  return methodPost(urlComplete, params)
}

export const editBudget = async (id, params) => {
  const urlComplete = API_INFO + `ecommerce/budgets/${id}/`
  return methodPut(urlComplete, params)
}

export const getVisits = async (query) => {
  const urlComplete = API_INFO + `intranet/visits/?usuario=${query}`
  return methodGet(urlComplete, query)
}

export const getBudgetList = async (query) => {
  const urlComplete = API_INFO + `ecommerce/budgets/?${query}`
  return methodGet(urlComplete)
}

export const getBudgetsByUser = async (query) => {
  const urlComplete = API_INFO + `ecommerce/budgets/by_user/?${query}`
  return methodGet(urlComplete)
}

export const getBudget = async (id) => {
  const urlComplete = API_INFO + `ecommerce/budgets/${id}/`
  return methodGet(urlComplete)
}

export const deleteBudget = async (id) => {
  const urlComplete = API_INFO + `ecommerce/budgets/${id}/`
  return methodDelete(urlComplete)
}
