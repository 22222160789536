import React from 'react'
// import { Row, Col } from 'react-bootstrap'

// own components
// import MenuMainIntranet from '../pages/intranet/header/index.js'
// import Cookies from '../components/cookiesAlert/cookies.js'
// import { companySelector } from '../slices/company.js'
// import { userSelector } from '../slices/user'
// import { useSelector } from 'react-redux'
// import AdminSideMenu from '../pages/intranet/sidebar/index.js'
import Intranet from '../pages/intranet/index.js'

const IntranetLayout = (props) => {
  // const { brandLogo } = useSelector(companySelector)
  // const { isLogged } = useSelector(userSelector)

  return (
    <>
      <Intranet />
    </>
  )
}

export default IntranetLayout
