import React, { useState, useEffect } from 'react'
import { AiFillEye, AiFillDelete } from 'react-icons/ai'

import { FaEdit } from 'react-icons/fa'
import { useHistory, Link } from 'react-router-dom'
import { BsSearch } from 'react-icons/bs'
import Button from 'react-bootstrap/Button'

//api
import { getListClients } from '../../../api/clients'
//customHook
import { useQuery } from '../../../utils/customHooks/query'
//components
import IntranetPagination from '../Pagination'

import CustomInput from '../../../components/customInput'
import CustomTable from '../../../components/table'
import ModalReadClient from './modalRead'
import ModalDeleteClient from './modalDelete'

const Clientes = () => {
  const history = useHistory()
  const [listClients, setListClients] = useState([])
  const searchParams = useQuery()
  const page = searchParams.get('page') || 1
  const pageSize = 10
  const [inputNameFiscal, setInputNameFiscal] = useState('')
  const [noResults, setNoResults] = useState(false)
  const [totalPages, setTotalPages] = useState(null)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [dataClient, setDataClient] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const query = {
    nombre_fiscal: inputNameFiscal,
    page: page,
    page_size: pageSize,
  }
  const searchQuery = new URLSearchParams(query)
  const queryString = searchQuery.toString()

  const getDataClients = async () => {
    try {
      const { data, status } = await getListClients(queryString)
      const { results } = data
      if (status === 200) {
        setListClients(results)
        setTotalPages(Math.ceil(data.count / pageSize))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDataClients()
  }, [])

  useEffect(() => {
    if (inputNameFiscal !== '') {
      handleSubmit()
    } else {
      getDataClients()
    }
  }, [page])

  const handleSubmit = async () => {
    try {
      const { data } = await getListClients(queryString)

      if (data.results.length > 0) {
        setListClients(data?.results)
        setNoResults(false)
        setTotalPages(Math.ceil(data.count / pageSize))
      } else {
        setListClients([])
        setNoResults(true)
      }
    } catch (error) {
      console.log(error)
    }
    history.push(`?${queryString}`)
    setIsActiveSearch(true)
  }

  const colsAuthors = ['CIF', 'Nombre Fiscal', 'Acciones']

  const handleValue = (e) => {
    setInputNameFiscal(e.target.value)
  }

  const handleClear = () => {
    setInputNameFiscal('')
    history.push('/intranet/clientes')
  }

  const openIsModal = (item, id, setOpenModal) => {
    if (item.id === id) {
      setOpenModal(true)
      setDataClient(item)
    }
  }

  return (
    <div>
      <div className="d-flex mt-3 justify-content-between">
        <h1>Clientes</h1>
        <Link to={`clientes/crear`}>
          <Button variant="secondary" type="submit" className="height-39 mr-4 ">
            Crear cliente
          </Button>
        </Link>
      </div>

      <div className="d-flex justify-content-end m-3">
        <CustomInput
          handleSubmit={(e) => handleSubmit(e)}
          onChange={handleValue}
          inputValue={inputNameFiscal}
          icon={<BsSearch color="white" />}
          placeholder={'Buscar por nombre fiscal'}
          type={'search'}
        />
        <button onClick={handleClear}>Limpiar url</button>
      </div>

      <CustomTable cols={colsAuthors} noResults={noResults}>
        {listClients &&
          listClients.map((listClient) => {
            return (
              <tr key={listClient.id}>
                <td className="text-center">{listClient.cif}</td>
                <td className="text-center">{listClient.nombre_fiscal}</td>
                <td className="text-center">
                  <AiFillEye
                    className="mr-5 cursor-pointer"
                    onClick={() =>
                      openIsModal(listClient, listClient.id, setOpenModal)
                    }
                  />
                  <Link to={`clientes/actualizar/${listClient.id}`}>
                    <FaEdit className="cursor-pointer" color="gray" />
                  </Link>
                  <AiFillDelete
                    className="cursor-pointer ml-5"
                    onClick={() =>
                      openIsModal(listClient, listClient.id, setIsOpenModal)
                    }
                  />
                </td>
              </tr>
            )
          })}
      </CustomTable>
      <ModalReadClient
        dataClient={dataClient}
        isModal={openModal}
        setIsModal={setOpenModal}
      />
      <ModalDeleteClient
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        getDataClients={getDataClients}
        id={dataClient?.id}
      />
      <IntranetPagination
        page={parseInt(page)}
        pages={totalPages}
        isActiveSearch={isActiveSearch}
        queryParams={queryString}
        getData={getListClients}
      />
    </div>
  )
}

export default Clientes
