import React from 'react'
import axios from 'axios'
import { API_INFO } from '../../../constants'

const Suscriptores = () => {
  const recieveExport = async () => {
    const options = {
      headers: {
        Authorization: 'JWT ' + localStorage.getItem('access'),
      },
      responseType: 'blob',
    }
    const { status, data } = await axios.post(
      API_INFO + 'company/subscription/export_subscriptions/',
      '',
      options
    )
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'Suscripciones.xls')
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  return (
    <div>
      <h1>Suscriptores</h1>
      <div className="d-flex justify-content-end m-3">
        <button onClick={recieveExport}>Export data</button>
      </div>
    </div>
  )
}

export default Suscriptores
