/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useMemo,
  Suspense,
  useCallback,
  useContext,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { cartSelector, addItem, decreaseQuantity } from '../../slices/cart'
import { userSelector } from '../../slices/user'
import { isEmpty } from 'lodash'

// context
import { UserContext } from '../../context/userContext'

//ui
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai'
import { MdShoppingBasket, MdFavoriteBorder, MdFavorite } from 'react-icons/md'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Button } from 'react-bootstrap'
import CoverImage from '../../components/coverImage/CoverImage'

//helpers
import { processAuthors, truncate, checkEmpty } from '../../helpers'

// own components
import ItemList from '../../components/itemList/item'
import MetaItem from '../../components/common/MetaItem/MetaItem'
import Head from '../../components/metatags_helmet/metatags'
import { Link } from 'react-router-dom'

//router
import { useParams } from 'react-router-dom'
//api
import { getDetailProduct, getRelatedProduct } from '../../api/products'
import { addSavedUser } from '../../api/lists'
import { addItemCart, decreaseQuantityItemCart } from '../../api/ecommerce'
import { removeItemFav } from '../../api/products'

//styles
import './detailItem.scss'

import { cleanHTML } from '../../utils'
import { companySelector } from '../../slices/company.js'
import Authors from '../../components/authors'

function BookDetail() {
  const dispatch = useDispatch()
  const { isLogged } = useSelector(userSelector)
  const { items } = useSelector(cartSelector)
  const [productDetail, setProductDetail] = useState('')
  const [relatedProducts, setRelatedProducts] = useState([])
  const [saved, setSaved] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [fullDescription, setFullDescription] = useState(false)
  const [available, setAvailable] = useState('')
  const [author, setAuthor] = useState()
  const { code } = useSelector(companySelector)

  let { type, url } = useParams()
  // let { type, pk } = location.state
  let history = useHistory()

  const { name } = useSelector(companySelector)

  // const getAuthor = async () => {
  //   const rs = await getDetailProduct(url, { type })
  //   if (rs.status === 200) {
  //     const { data } = rs
  //     setAuthor(data.display_autor[0].url)
  //   }
  // }

  const handleDetailProduct = async () => {
    const rs = await getDetailProduct(url, { type })
    if (rs.status === 200) {
      const { data } = rs
      setSaved(data.favorite)
      setProductDetail(data)
      if (data.type === 'libro') {
        setAvailable(data.available.status)
      } else {
        setAvailable(true)
      }
      setTitle(
        data.type == 'merchandising'
          ? `merchandising ${data.titulo}`
          : data.type == 'cine'
          ? `película ${data.titulo}`
          : `${data.titulo} | Comprar Libro`
      )
      const editorial = data.display_editorial
        ? data.display_editorial.nombre_fiscal
        : ''
      const autor = data?.display_autor?.[0]
        ? data?.display_autor[0].nombre_autor
        : ''
      setDescription(
        data.type == 'cine'
          ? `La película ${data.titulo} del estudio ${editorial}, está a la venta de forma online en ${name}.`
          : data.type == 'merchandising'
          ? `Si eres fan de ${data.titulo}, en ${name} tenemos un producto de merchandising que te puede gustar. ¡No te lo pierdas!`
          : `El libro ${data.titulo} del autor ${autor} con EAN ${data.ean}, está a la venta de forma online en ${name}.`
      )
    }
  }

  const goUp = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    handleDetailProduct()
    goUp()
    // getAuthor()
  }, [url, type])

  const dvdProductsRedirect = () => {
    if (type == 'cine') {
      history.push('/')
    }
  }

  useEffect(() => {
    const handleRelatedProducts = async () => {
      const fields = [
        'id',
        'titulo',
        'url',
        'get_url',
        'autor',
        'type',
        'precio_venta_publico',
      ].join(',')
      const rs = await getRelatedProduct(url, { type, fields: fields })
      if (rs.status === 200) {
        const { data } = rs
        setRelatedProducts(data)
      }
    }
    handleRelatedProducts()
    dvdProductsRedirect()
  }, [url, type])

  const handleAddSavedUser = async () => {
    const rs = await addSavedUser({ product_id: productDetail.id })
    if (rs.status === 200) {
      setSaved(true)
    }
  }

  const deleteFavorite = async () => {
    const payload = { product_id: productDetail.id }
    const rs = await removeItemFav(payload, productDetail.id)
    if (rs.status === 204) {
      setSaved(false)
    }
  }

  const { isSignUpModalOpen, showSignUpModal } = useContext(UserContext)

  const handleShowModal = useCallback(
    () => showSignUpModal(true),
    [showSignUpModal]
  )

  const getCountItems = (() => {
    let ctnItems = 0
    if (checkEmpty(productDetail)) {
      const item = items.find((item) => item.id === productDetail.id)
      ctnItems = item ? item.quantity : 0
    }
    return ctnItems
  })()

  const addProductToCart = async (data) => {
    if (isLogged) {
      const payload = { item: data.id, quantity: 1 }
      const { status, dataResponse } = await addItemCart(payload)
      if (status === 201) {
        dispatch(addItem(data))
      } else {
        console.log('error')
      }
    } else {
      dispatch(addItem(data))
    }
  }

  const handleRemoveItem = async () => {
    const ctnItems = getCountItems
    if (ctnItems === 0) return
    else if (ctnItems >= 1) {
      if (isLogged) {
        const payload = { item: productDetail.id }
        const { status, dataResponse } = await decreaseQuantityItemCart(payload)
        if (status === 200) {
          dispatch(decreaseQuantity(productDetail.id))
        } else {
          console.log('error')
        }
      } else {
        dispatch(decreaseQuantity(productDetail.id))
      }
    }
  }

  const renders = {
    renderRelatedProcuts() {
      if (relatedProducts.length === 0) return null
      const compRelatedProducts = relatedProducts.map((item, i) => (
        <Col xs={6} sm={6} md={4} lg={12} key={i}>
          <ItemList key={i} data={item} />
        </Col>
      ))
      return compRelatedProducts
    },
  }

  return (
    <div className="container">
      <Head description={description} title={title} />
      <Container fluid>
        <Row className="m-2">
          <Col md={12} lg={12}>
            <Breadcrumb className="breadcrum-item">
              <Breadcrumb.Item onClick={() => history.push('/')}>
                Inicio
              </Breadcrumb.Item>
              {type === 'libro' && (
                <Breadcrumb.Item
                  onClick={() => history.push('/filtro/' + 'libros' + '/')}
                >
                  {type}
                </Breadcrumb.Item>
                // ) : (
                //   <Breadcrumb.Item
                //     onClick={() => history.push('/filtro/' + 'peliculas' + '/')}
                //   >
                //     {type}
                //   </Breadcrumb.Item>
              )}

              <Breadcrumb.Item active>
                {productDetail.titulo
                  ? productDetail.titulo.substring(0, 20).toLowerCase() + '...'
                  : ''}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className="m-2">
          <Col lg={9}>
            <Row lg={12}>
              <Col md={4} lg={3} className="mb-2 imgDetail">
                {!isEmpty(productDetail) && (
                  <CoverImage
                    title={productDetail.titulo}
                    src={productDetail.get_url}
                  />
                )}

                {!isLogged ? (
                  <div
                    className=" color-primary-c my-4"
                    style={{ textAlign: 'center' }}
                  >
                    <button
                      onClick={handleShowModal}
                      style={{ cursor: 'pointer' }}
                    >
                      <MdFavoriteBorder style={{ fontSize: 24 }} /> Guardar
                    </button>
                  </div>
                ) : (
                  <div
                    className="color-primary my-4"
                    style={{ textAlign: 'center' }}
                  >
                    {!saved ? (
                      <button onClick={handleAddSavedUser}>
                        <MdFavoriteBorder style={{ fontSize: 24 }} /> Guardar
                      </button>
                    ) : (
                      <button onClick={deleteFavorite}>
                        <MdFavorite style={{ fontSize: 24 }} /> Guardado
                      </button>
                    )}
                  </div>
                )}
              </Col>
              <Col md={6} lg={9} className="pl-0">
                <Row md={12} className="p-0">
                  <Col md={12} className="title-item">
                    <h4>{productDetail.titulo}</h4>
                  </Col>
                </Row>
                <Row md={12}>
                  <Col md={12} className="autor-item">
                    <p>
                      Autores:{' '}
                      {productDetail.display_autor && (
                        <Authors elements={productDetail.display_autor} />
                      )}
                    </p>
                  </Col>
                </Row>

                <Row md={6} className="editorial-item">
                  <Col md={6}>
                    {productDetail !== '' && productDetail.display_editorial ? (
                      <p>
                        Editorial:{' '}
                        <Link
                          className="store-item__link"
                          to={`/editorial/${productDetail.display_editorial.url}/`}
                        >
                          <span>
                            {productDetail.display_editorial.nombre_fiscal}
                          </span>
                        </Link>
                      </p>
                    ) : (
                      <p>
                        Editorial: <span>No especificado</span>
                      </p>
                    )}
                  </Col>
                  <Col md={6}>
                    {available ? (
                      <p
                        style={
                          available ? { color: 'green' } : { color: 'red' }
                        }
                        dangerouslySetInnerHTML={{
                          __html:
                            productDetail.type === 'libro' ||
                            productDetail.type === 'producto'
                              ? productDetail.available.text
                              : 'Disponibilidad inmediata 24-48hs',
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
                <Row sm={12} md={12} lg={12} className="buttons-row pl-3">
                  <Col sm={2} md={4} lg={2} className="price-item my-1">
                    {productDetail.precio_venta_publico} €
                  </Col>
                  <Col
                    sm={{ span: 4, offset: 6 }}
                    md={{ span: 7, offset: 1 }}
                    lg={{ span: 4, offset: 6 }}
                    className="text-center my-1"
                  >
                    {getCountItems ? (
                      <>
                        <AiFillMinusCircle
                          className="icon-md quantity-item"
                          onClick={handleRemoveItem}
                        />
                        <span>{getCountItems}</span>
                        <AiFillPlusCircle
                          className="icon-md quantity-item"
                          onClick={() => addProductToCart(productDetail)}
                        />
                      </>
                    ) : available ? (
                      <Button
                        className={available ? 'buttonCart textButtonCart' : ''}
                        size="sm"
                        variant={available ? 'outline-dark' : 'secondary'}
                        title={available ? null : 'Stock no disponible'}
                        onClick={
                          available
                            ? () => addProductToCart(productDetail)
                            : null
                        }
                      >
                        <MdShoppingBasket />{' '}
                        <span style={available ? { fontWeight: 'bold' } : null}>
                          Añadir a la cesta
                        </span>
                      </Button>
                    ) : productDetail.available !== undefined ? (
                      <p
                        style={
                          available ? { color: 'green' } : { color: 'red' }
                        }
                      >
                        {productDetail.available.text}
                      </p>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
                <Row md={12} className="description-item pt-3">
                  <Col md={12}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: checkEmpty(productDetail)
                          ? fullDescription
                            ? cleanHTML(productDetail.texto_resumen)
                            : truncate(
                                cleanHTML(productDetail.texto_resumen),
                                400
                              )
                          : '',
                      }}
                    />
                  </Col>
                  {!productDetail.texto_resumen ? (
                    ''
                  ) : productDetail.texto_resumen.length > 400 ? (
                    <Button
                      className="mb-3"
                      size="sm"
                      variant="link"
                      onClick={() => {
                        fullDescription
                          ? setFullDescription(false)
                          : setFullDescription(true)
                      }}
                    >
                      <h6
                        dangerouslySetInnerHTML={{
                          __html: fullDescription
                            ? 'VER MENOS...'
                            : 'VER MAS...',
                        }}
                      />
                    </Button>
                  ) : (
                    ''
                  )}
                </Row>
              </Col>
            </Row>
            <Row lg={12} className="m-1 pt-3 detail">
              <Col lg={12} className="description pl-0 my-2 pl-2">
                <h5>Descripción</h5>
              </Col>
              <Col lg={12}>
                <MetaItem product={productDetail} />
              </Col>
            </Row>
          </Col>
          <Col lg={3}>
            <Row>
              <Col lg={12} className="p-0">
                <h4 className="text-center mt-3">Productos Relacionados</h4>
              </Col>
            </Row>
            <Row lg={12}>{renders.renderRelatedProcuts()}</Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BookDetail
