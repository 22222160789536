import React from 'react'
import Table from 'react-bootstrap/Table'

const CustomTable = ({ cols, children, noResults }) => {
  const NoResults = ({ colCount }) => {
    return (
      <tr className="w-100 text-center">
        <td colSpan={colCount} className="no-results-td">
          No hay resultados
        </td>
      </tr>
    )
  }

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            {cols.map((col) => (
              <th className="text-center" key={col}>
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>

        {noResults && <NoResults colCount={7} />}
      </Table>
    </div>
  )
}

export default CustomTable
