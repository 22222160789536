import { methodGet, methodPut } from './methods'
import { API_INFO } from '../constans'

export const getPosts = async (query) => {
  return methodGet(API_INFO + 'blog/posts/published/', query)
}

export const getPost = async ({ year, month, day, slug }) => {
  return methodGet(API_INFO + `blog/posts/${year}/${month}/${day}/${slug}`)
}

export const getPostSortVisits = async () => {
  return methodGet(API_INFO + 'blog/posts/published/?sort=visits')
}

export const editPost = async (id) => {
  const completeUrl = API_INFO + `blog/posts/${id}/`
  return methodPut(completeUrl)
}
