import React, { useEffect, useState } from 'react'
import 'react-material-stepper/dist/react-stepper.css'
import { Row, Col, Card, Container, Jumbotron, Table } from 'react-bootstrap'
import { cartSelector } from '../../slices/cart'
import { companySelector } from '../../slices/company'
import { useSelector } from 'react-redux'
import { COMPANY } from '../../constans'

import Stepper, { Step } from 'react-material-stepper'
import Items from './items'
import BillingAddress from './billing_address'
import ShippingAddress from './shipping_address'
import PaymentMethods from './payment_methods'
import useScript from 'react-script-hook'

import './cart.scss'
import { Link } from 'react-router-dom'

const Cart = () => {
  const [detailOrder, setDetailOrder] = useState({
    numOrder: '',
    paymentMethod: '',
  })
  const { countItems, subtotal, iva, total, shippingCosts } =
    useSelector(cartSelector)

  const {
    data,
    paypal_sandbox,
    paypal_production,
    direccion_completa,
    telefono,
    name,
  } = useSelector(companySelector)
  const [isFinishedShop, setIsFinishedShop] = useState(false)

  const goUp = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    window.document.title = 'Carrito de compras | ' + COMPANY
    goUp()
  }, [])

  const CLIENT = {
    sandbox: paypal_sandbox,
    production: paypal_production,
  }

  const CLIENT_ID =
    process.env.NODE_ENV === 'production' ? CLIENT.production : CLIENT.sandbox

  useScript({
    src: `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=EUR`,
  })

  const onFinish = (num_order, payment_method) => {
    setIsFinishedShop(true)
    setDetailOrder({
      numOrder: num_order,
      paymentMethod: payment_method,
    })
  }

  return (
    <Container className="sm-fluid">
      <Row>
        <Col md={12} className="title">
          <h2>Carrito de Compras</h2>
          <span className="divider-title"></span>
        </Col>
      </Row>
      {countItems > 0 ? (
        <Row>
          <Col md={9}>
            <Stepper className="checkout-stepper">
              <Step
                stepId="items"
                data="Items"
                title="Items"
                description="Detalle del carrito"
              >
                <Items isResume={false} />
              </Step>
              <Step
                stepId="shipping_address"
                data="ShippingAddress"
                title="Envío"
                description="Dirección de Envío"
              >
                <ShippingAddress />
              </Step>
              <Step
                stepId="billing"
                data="billing"
                title="Forma de Pago"
                description="Elija la forma de pago"
              >
                <PaymentMethods onFinish={onFinish} />
              </Step>
            </Stepper>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body>
                <Card.Title>Tu Pedido</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {countItems} items
                </Card.Subtitle>
                <p>Subtotal: {subtotal} €</p>
                <p>IVA: {iva} €</p>
                <p>
                  Envío para España:{' '}
                  {shippingCosts > 0 ? shippingCosts + ' €' : <b>Gratis</b>}{' '}
                </p>
                <p>
                  Total:{' '}
                  {(parseFloat(total) + parseFloat(shippingCosts)).toFixed(2)} €
                </p>
                <Link
                  to="/presupuesto"
                  className="mdc-button stepper__action button mine mdc-button--unelevated mdc-button-align--right"
                >
                  Crear presupuesto
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            {isFinishedShop ? (
              <Jumbotron style={{ textAlign: 'center' }}>
                <p
                  style={{
                    color: '#19c51f',
                    fontWeight: 'bold',
                    fontSize: '19px',
                  }}
                >
                  ¡Pedido procesado con éxito!
                </p>
                <p>Recibirás un email con los datos de este.</p>
                {parseInt(detailOrder.paymentMethod) === 1 ? (
                  <Row style={{ justifyContent: 'center' }}>
                    <Col md={10}>
                      <Table bordered hover variant="dark">
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              <h3>Realizar transferencia a:</h3>{' '}
                            </td>
                          </tr>
                          <tr>
                            <td>Cuenta bancaria :</td>
                            <td>{data.bank_account}</td>
                          </tr>
                          <tr>
                            <td>Concepto:</td>
                            <td>{detailOrder.numOrder} </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col md={8} style={{ marginTop: 30 }}>
                      <p>
                        Por favor para completar su compra realize una
                        transferencia al número de cuenta{' '}
                        <b>{data.bank_account}</b> con concepto el numero de
                        pedido : <b>{detailOrder.numOrder}</b>.
                      </p>
                      <p>
                        Las trasferencias pueden tardar de 2 a 3 días en
                        recibirse. Una vez hayamos recibido el pago prepararemos
                        el envío de su pedido.
                      </p>
                      <p>Gracias</p>
                    </Col>
                  </Row>
                ) : parseInt(detailOrder.paymentMethod) === 5 ? (
                  <>
                    <br />
                    <hr />
                    <br />
                    <p>
                      Deberá abonarse en la tienda al momento del retiro. Tendrá
                      72hs hábiles para proceder a retirarlo; en caso de no
                      poder hacerlo en este plazo comuníquese con la tienda para
                      consultar la reserva de este.
                    </p>
                    <p>
                      {' '}
                      Librería: <strong>{name}</strong>{' '}
                    </p>
                    <p>
                      {' '}
                      Dirección: <strong>{direccion_completa}</strong>{' '}
                    </p>
                    <p>
                      {' '}
                      Teléfono: <strong>{telefono}</strong>{' '}
                    </p>
                    <p>
                      Número de pedido : <b>{detailOrder.numOrder}</b>.
                    </p>
                  </>
                ) : null}
              </Jumbotron>
            ) : (
              <Jumbotron style={{ textAlign: 'center' }}>
                <p>El carrito está vacio</p>
              </Jumbotron>
            )}
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default Cart
