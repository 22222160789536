import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { FormGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
//api
import { createList } from '../../../../api/lists-intranet'
//components
import ModalCustom from '../../../../components/modal'
import CustomMultipleInput from '../../../../components/custommultipleInput/index'

const ModalCreate = ({ openModal, setOpenModal, getDataList }) => {
  const {
    register,
    reset,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: {
      nombre: '',
      url: '',
    },
  })

  const handleClose = () => {
    setOpenModal(false)
  }

  const resetData = {
    nombre: '',
    url: '',
  }

  const onSubmit = async (values) => {
    const data = {
      nombre: values.nombre,
      url: values.url,
    }

    try {
      const response = await createList(data)
      if (response.status === 201) {
        getDataList()
        reset(resetData)
        setOpenModal(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const ButtonFooter = () => {
    return (
      <div className="text-center d-flex justify-content-end ">
        <Button variant="secondary" onClick={handleClose} className="mx-3">
          Cerrar
        </Button>
        <Button variant="secondary" type="submit">
          Enviar
        </Button>
      </div>
    )
  }

  return (
    <ModalCustom show={openModal} title="Crear Lista">
      <CustomMultipleInput onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-wrap ml-5">
          <FormGroup className="d-flex flex-column width-40">
            <label>Nombre</label>
            <input
              type="text"
              className="input-round"
              {...register('nombre', {})}
            />
          </FormGroup>
          <FormGroup className="d-flex flex-column width-40 margin-left-15">
            <label>Url</label>
            <input
              type="text"
              className="input-round"
              {...register('url', {})}
            />
          </FormGroup>
        </div>
        <ButtonFooter />
      </CustomMultipleInput>
    </ModalCustom>
  )
}

export default ModalCreate
