export function processAuthors(object) {
  if (!object.display_autor) return null
  let textAutor = ''
  const autores = object.display_autor
    .map((value, i) => {
      return value.nombre_autor
    })
    .join(',')
  textAutor = autores ? `${autores}` : 'No especifica'
  return textAutor
}

export function getAuthorLink(object) {
  if (!object.display_autor) return null
  let linkAuthor = ''
  const authors = object.display_autor.map((value, i) => {
    return value.url
  })
  linkAuthor = authors && `${authors}`
  return linkAuthor
}

export function truncate(input, length) {
  if (input && input.length > length) {
    return input.substring(0, length) + '...'
  } else {
    return input
  }
}

export function storeLocal(key, data, isObject) {
  isObject
    ? localStorage.setItem(key, JSON.stringify(data))
    : localStorage.setItem(key, data)
}

export function unSetLocal(key) {
  localStorage.removeItem(key)
}

export function checkEmpty(object) {
  return Object.keys(object).length > 0 ? true : false
}

export function capitalize(word) {
  if (typeof word !== 'string') return ''
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

/**
 * @param {string} key - the key property to check out in the localstorage
 */
export const getItemStore = (key) => localStorage.getItem(key)
