import { methodGet, methodPost } from './methods'
import { API_INFO } from '../constans'

export const getCategories = async () => {
  return methodGet(API_INFO + 'company/gallery/categories/')
}

export const getGallery = async () => {
  return methodGet(API_INFO + 'company/gallery/')
}

export const getEvents = async (query) => {
  return methodGet(API_INFO + 'company/events/', query)
}

export const getEvent = async (id) => {
  return methodGet(API_INFO + 'company/events/' + id)
}

export const getCountries = async () => {
  return methodGet(API_INFO + 'company/countries/')
}

export const getCompany = async () => {
  return methodGet(API_INFO + 'company/my-info/')
}

export const getLocalities = async (params) => {
  return methodGet(API_INFO + 'company/localities/', params)
}

export const getMetadataCompany = async () => {
  return methodGet(API_INFO + 'company/metadata-company/')
}

export const getProvinces = async (params) => {
  return methodGet(API_INFO + 'company/provinces/', params)
}

export const getLanguages = async (query) => {
  return methodGet(`${API_INFO}company/languages/${query}`)
}

export const getLocalitiesByProvince = async (params) => {
  return methodGet(API_INFO + 'company/localitiesByProvince/', params)
}

export const getCSData = async () => {
  return methodGet(API_INFO + 'company/coffee-shop/')
}

export const getBookingForm = async (params) => {
  return methodGet(`${API_INFO}user/reservation/send/`, params)
}

export async function postSubscriptionEmail(payload) {
  const url = API_INFO + 'company/subscription/'
  return methodPost(url, payload)
}
