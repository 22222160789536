import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { FormGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
//api
import { createUserClient } from '../../api/clients'
//icons
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
//components
import ModalCustom from '../modal/index'
import CustomMultipleInput from '../custommultipleInput'

const ModalCreateUserClient = ({ listUserClient, openModal, setOpenModal }) => {
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      username: '',
      email: '',
      biblioteca: null,
      password: '',
      repeat_password: '',
    },
  })
  const [passwordShown, setPasswordShown] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const resetData = {
    username: '',
    email: '',
    biblioteca: null,
    password: '',
    repeat_password: '',
  }

  const handleClose = () => {
    setOpenModal(false)
    reset(resetData)
    setErrorMessage()
  }

  const togglePasswordVisiblity = (passwordShown, setPasswordShown) => {
    setPasswordShown(passwordShown ? false : true)
  }

  const onSubmit = async (values) => {
    const data = {
      username: values.username,
      email: values.email,
      biblioteca: values.biblioteca,
      password: values.password,
      repeat_password: values.repeat_password,
    }

    try {
      const response = await createUserClient(data)
      console.log(response)
      if (response.status === 201) {
        listUserClient()
        setOpenModal(false)
        reset(resetData)
      } else if (response.status === 400) {
        setErrorMessage('El nombre de usuario ya existe')
      } else {
        setErrorMessage('Error en el servidor')
      }
    } catch (error) {
      console.log(error)
    }
  }
  const ButtonFooter = ({ onSubmit }) => {
    return (
      <div className="d-flex justify-content-end mt-3 ">
        <Button variant="secondary" onClick={onSubmit} className="mr-3">
          Enviar
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
      </div>
    )
  }
  return (
    <>
      <ModalCustom
        show={openModal}
        title={<p className="text-center">Crear usuario</p>}
      >
        {errorMessage && (
          <p className="text-danger text-center ">{errorMessage}</p>
        )}
        <CustomMultipleInput
          onSubmit={handleSubmit(onSubmit)}
          className="d-flex h-100 flex-wrap justify-content-center p-3"
        >
          <FormGroup className="d-flex flex-column width-flex">
            <label>Nombre de usuario *</label>
            <input
              type="text"
              className="input-round"
              {...register('username', {
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
                minLength: {
                  value: 4,
                  message:
                    'El nombre de usuario debe tener mínimo 4 caracteres',
                },
                maxLength: {
                  value: 20,
                  message:
                    'El nombre de usuario debe tener máximo 20 caracteres',
                },
              })}
            />

            {errors.username && (
              <small className="text-danger">{errors.username.message}</small>
            )}
          </FormGroup>
          <FormGroup className="margin-left-15 d-flex flex-column width-flex">
            <label>Correo electrónico *</label>
            <input
              type="email"
              className="input-round"
              {...register('email', {
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
                validate: {
                  matchPattern: (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    'Debe ser un correo electrónico válido',
                },
              })}
            />

            {errors.email && (
              <small className="text-danger">{errors.email.message}</small>
            )}
          </FormGroup>
          <FormGroup className="d-flex flex-column width-flex">
            <label>Biblioteca</label>
            <input
              type="number"
              className="input-round"
              {...register('biblioteca')}
            />
          </FormGroup>
          <FormGroup className="margin-left-15 d-flex flex-column width-flex position-relative">
            <label>Contraseña *</label>
            <input
              type={passwordShown ? 'text' : 'password'}
              className="input-round"
              {...register('password', {
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
                minLength: {
                  value: 6,
                  message: 'la contraseña debe tener mínimo 6 caracteres',
                },
                maxLength: {
                  value: 20,
                  message: 'El contraseña debe tener máximo 20 caracteres',
                },
              })}
            />
            <span
              className=" eye-password "
              onClick={() =>
                togglePasswordVisiblity(passwordShown, setPasswordShown)
              }
            >
              {' '}
              {passwordShown ? <AiFillEyeInvisible /> : <AiFillEye />}{' '}
            </span>

            {errors.password && (
              <small className="text-danger">{errors.password.message}</small>
            )}
          </FormGroup>
          <FormGroup className="d-flex flex-column position-relative width-flex">
            <label>Confirmar contraseña *</label>
            <input
              type={confirmPassword ? 'text' : 'password'}
              className="input-round"
              {...register('repeat_password', {
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
                validate: (value) => {
                  if (watch('password') !== value) {
                    return 'Las contraseñas no coinciden'
                  }
                },
              })}
            />
            <span
              className="eye-password "
              onClick={() =>
                togglePasswordVisiblity(confirmPassword, setConfirmPassword)
              }
            >
              {' '}
              {confirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}{' '}
            </span>
            {errors.repeat_password && (
              <small className="text-danger">
                {errors.repeat_password.message}
              </small>
            )}
          </FormGroup>
          <FormGroup className="margin-left-15 d-flex flex-column width-flex">
            <label>Cliente</label>
            <input
              type="text"
              className="input-round"
              {...register('cliente')}
            />
          </FormGroup>
        </CustomMultipleInput>
        <ButtonFooter onSubmit={handleSubmit(onSubmit)} />
      </ModalCustom>
    </>
  )
}

export default ModalCreateUserClient
