import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'

import Clientes from '../Clientes'
import CreateClient from '../Clientes/crear'
import EditClient from '../Clientes/actualizar/index'
import Productos from '../Productos'
import Autores from '../Autores'
import Pedidos from '../Pedidos'
import Suscriptores from '../Suscriptores'
import GestionarPosts from '../GestionarPosts'
import SliderClientes from '../SliderClientes'
import Galeria from '../Galeria'
import Merchandising from '../Merchandising'
import Slider from '../Slider'
import Listas from '../Listas'
import Editoriales from '../Editoriales'
import PreparacionPedidos from '../PreparacionPedidos'

const Main = () => {
  return (
    <>
      <BrowserRouter>
        <Route exact path="/intranet" />
        <Route exact path="/intranet/clientes" component={Clientes} />
        <Route exact path="/intranet/clientes/crear" component={CreateClient} />
        <Route
          exact
          path="/intranet/clientes/actualizar/:id"
          component={EditClient}
        />
        <Route exact path="/intranet/productos" component={Productos} />
        <Route exact path="/intranet/autores" component={Autores} />
        <Route exact path="/intranet/pedidos" component={Pedidos} />
        <Route exact path="/intranet/suscriptores" component={Suscriptores} />
        <Route
          exact
          path="/intranet/gestionarPosts"
          component={GestionarPosts}
        />
        <Route
          exact
          path="/intranet/sliderClientes"
          component={SliderClientes}
        />
        <Route exact path="/intranet/galeria" component={Galeria} />
        <Route exact path="/intranet/merchandising" component={Merchandising} />
        <Route exact path="/intranet/slider" component={Slider} />
        <Route exact path="/intranet/listas" component={Listas} />
        <Route exact path="/intranet/editoriales" component={Editoriales} />
        <Route
          exact
          path="/intranet/preparacionPedidos"
          component={PreparacionPedidos}
        />
      </BrowserRouter>
    </>
  )
}

export default Main
