import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react'
import { Navbar, Row, Col } from 'react-bootstrap'
import { AiOutlineUser, AiOutlineShoppingCart } from 'react-icons/ai'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSpring, animated } from 'react-spring'
import delay from 'delay'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

//redux
import { cartSelector, clear, clearShippingData } from '../../../slices/cart'
import { useSelector } from 'react-redux'

//api
import LoginModal from '../../../pages/login'
import { logoutApi } from '../../../api/login'
import { logoutSuccess } from '../../../slices/user'

// context
import { UserContext } from '../../../context/userContext'

//helpers
import { unSetLocal } from '../../../helpers'

// own components
import DropMenuLogged from '../../../components/navbar/DropMenuLogged'

const MenuMainIntranet = ({ brandLogo, menus, isLogged }) => {
  const { isSignUpModalOpen, showSignUpModal, isRegister } =
    useContext(UserContext)
  const [showToast, setShowToast] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const handleCloseModal = useCallback(
    () => showSignUpModal(false),
    [showSignUpModal]
  )
  const handleShowModal = useCallback(
    () => showSignUpModal(true),
    [showSignUpModal]
  )

  const { countItems, lastAddedItem, lastRemovedItem, isIncrease } =
    useSelector(cartSelector) || {}
  const previousCounterValue = useRef(countItems)
  const dispatch = useDispatch()
  const headerRef = useRef()
  const history = useHistory()

  const [counterAnimProps, set] = useSpring(() => ({
    backgroundColor: 'white',
    transform: 'translateY(0px)',
    config: {
      tension: 160,
      friction: 20,
    },
  }))

  useScrollPosition(
    ({ currPos }) => {
      setIsSticky(Math.abs(currPos.y) > headerRef.current.offsetHeight)
    },
    [],
    null,
    null,
    200
  )

  useEffect(() => {
    ;(async () => {
      if (countItems !== previousCounterValue.current) {
        set({
          backgroundColor: 'rgb(145, 214, 255)',
          transform: 'translateY(-2px)',
        })
        await delay(180)
        setShowToast(true)
        set({ backgroundColor: 'white', transform: 'translateY(0px)' })
      }
    })()
  }, [countItems])

  useEffect(() => {
    ;(async () => {
      if (showToast) {
        await delay(4000)
        setShowToast(false)
      }
    })()
  }, [showToast])

  const closeSession = async () => {
    const { status } = await logoutApi({
      refresh_token: localStorage.getItem('refresh'),
    })
    if (status === 200) {
      unSetLocal('access')
      unSetLocal('refresh')
      unSetLocal('permissions')
      unSetLocal('type_user')
      dispatch(clear())
      dispatch(clearShippingData())
      dispatch(logoutSuccess())
      history.push('/')
    }
  }

  return (
    <header
      className={`header  container-full ${
        isSticky ? 'header--sticky' : ''
      } justify-content-end`}
    >
      <div ref={headerRef} className={`container-full shadow-bottom w-20`}>
        <div className="container">
          <Row className="header__container">
            <Col className="d-none d-md-block header__brand">
              <Link to={'/'}>
                <img alt="Logotipo Empresa" src={brandLogo} />
              </Link>
            </Col>
            <Col>
              <p
                className="text-center mt-3"
                style={{
                  fontWeight: 'bold',
                  fontSize: '25px',
                  fontFamily: 'Montserrat',
                }}
              >
                Intranet
              </p>
            </Col>
            <Col className="d-none d-md-block header__actions">
              <Row
                as="nav"
                aria-label="menú de compras y usuario"
                className="justify-content-end align-items-center header__menu"
              >
                <ul>
                  {isLogged ? (
                    <li>
                      <DropMenuLogged
                        dropDirection="down"
                        closeSession={closeSession}
                      />
                    </li>
                  ) : (
                    <li>
                      <button
                        className="header__button header__button--user"
                        aria-label="usuario"
                        onClick={handleShowModal}
                      >
                        <AiOutlineUser role="none" className="icon-md" />
                      </button>
                    </li>
                  )}
                </ul>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="container-full shadow-bottom">
        <div className="container header__nav ">
          <Navbar className="bg-transparency header__main-nav" expand="md">
            <Row>
              <Col xs={12}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <img
                  className="d-none d-sm-inline d-md-none ml-4 logo--mobile"
                  height="50"
                  alt="logo"
                  src={brandLogo}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-sm-block d-md-none">
                <Row
                  role="menubar"
                  className="justify-content-end align-items-center"
                >
                  <Link
                    className="header__button header__button--cart"
                    to="/carrito"
                  >
                    <AiOutlineShoppingCart className="icon-md" />
                    {countItems > 0 && (
                      <animated.span
                        style={counterAnimProps}
                        className="cart-counter"
                      >
                        {countItems}
                      </animated.span>
                    )}
                    {showToast && (
                      <div className="cart-toast">
                        <span>
                          Has {isIncrease ? 'añadido ' : 'quitado '}
                          {isIncrease ? (
                            <strong>"{lastAddedItem}"</strong>
                          ) : (
                            <strong>"{lastRemovedItem}"</strong>
                          )}{' '}
                          {isIncrease ? 'al' : 'del'} carrito
                        </span>
                      </div>
                    )}
                  </Link>
                  {isLogged ? (
                    <DropMenuLogged
                      className="header__button header__button--logged"
                      dropDirection="left"
                      closeSession={closeSession}
                    />
                  ) : (
                    <button className="header__button header__button--user ml-2">
                      <AiOutlineUser
                        onClick={handleShowModal}
                        className="icon-md"
                      />
                    </button>
                  )}
                </Row>
              </Col>
            </Row>
          </Navbar>
        </div>
      </div>
      {isSignUpModalOpen ? (
        <LoginModal
          show={isSignUpModalOpen}
          handleClose={handleCloseModal}
          register={isRegister}
        />
      ) : null}
    </header>
  )
}

export default MenuMainIntranet
