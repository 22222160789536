import { methodGet, methodPost } from './methods'
import { API_INFO } from '../constans'

const url_base = API_INFO + 'products/'

export async function getListsHome() {
  const url = API_INFO + 'products/listas/get-lists-home'
  return methodGet(url, {})
}

export async function getMerchandising() {
  const url = API_INFO + 'products/listas/get-lists-merchandising'
  return methodGet(url, {})
}

export async function getDetailList(slug) {
  const url = API_INFO + 'products/listas/' + slug
  return methodGet(url, {})
}

export async function getElementsList(slug, page) {
  const url =
    API_INFO + 'products/listas/' + slug + '/get-products?page=' + page
  return methodGet(url, {})
}

export const addSavedUser = (params) => {
  const url = url_base + 'listas/add-saved-user/'
  return methodPost(url, params)
}

export const favorites = () => {
  const url = url_base + 'listas/saved-user'
  return methodGet(url)
}

export async function getEditorialList(page = 1, pageSize = 10) {
  const url =
    API_INFO + `products/editorials/?page=${page}&page_size=${pageSize}`
  return methodGet(url, {})
}
