import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { AiOutlineUser } from 'react-icons/ai'
import { getItemStore } from '../../helpers'

const DropMenuLogged = (props) => {
  const typeUser = getItemStore('type_user')

  return (
    <DropdownButton
      drop={props.dropDirection}
      className="btn-logged header__button header__button--logged"
      size="small"
      id="dropdown-basic-button"
      title={<AiOutlineUser className="icon-sm" />}
    >
      <Dropdown.Item href="/perfil">Perfil</Dropdown.Item>
      <Dropdown.Item href="/mis-pedidos">Mis Pedidos</Dropdown.Item>
      <Dropdown.Item href="/presupuestos">Lista de presupuesto</Dropdown.Item>
      {/*<Dropdown.Item href="/profile">Pedidos</Dropdown.Item>
      <Dropdown.Item href="#">Pedidos especiales</Dropdown.Item>
    <Dropdown.Item href="#">Control pedidos bibliotecas</Dropdown.Item>*/}
      <Dropdown.Item href="/favoritos">Mis guardados</Dropdown.Item>
      {typeUser === 'trabajador' && (
        <Dropdown.Item href="/intranet">Intranet</Dropdown.Item>
      )}
      <Dropdown.Item onClick={() => props.closeSession()}>
        Cerrar sesión
      </Dropdown.Item>
    </DropdownButton>
  )
}

export default DropMenuLogged
