import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
//api
import { readUserClient } from '../../../api/clients'
//components
import ModalCustom from '../../modal'

const ModalReadUserClient = ({ idUser, openModal, setOpenModal }) => {
  const [userClient, setUserClient] = useState()

  const getDataUser = async () => {
    try {
      const { data } = await readUserClient(idUser)
      setUserClient(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (openModal) {
      getDataUser()
    }
  }, [openModal])

  const handleClose = () => {
    setOpenModal(false)
  }

  const ButtonFooter = () => {
    return (
      <div className="text-center d-flex justify-content-end">
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </div>
    )
  }

  return (
    <ModalCustom show={openModal} title="Detalle usuario">
      <div className="d-flex flex-wrap p-3">
        <div className="w-50">
          <p className="font-weight-bold">Nombre de usuario:</p>
          <p>{userClient?.username}</p>
        </div>
        <div className="w-50">
          <p className="font-weight-bold">Correo electrónico:</p>
          <p>{userClient?.email}</p>
        </div>
        <div className="w-50">
          <p className="font-weight-bold">Empresa:</p>
          <p>{userClient?.empresa}</p>
        </div>
        <div className="w-50">
          <p className="font-weight-bold">Biblioteca:</p>
          <p>{userClient?.biblioteca}</p>
        </div>
        <div className="w-50">
          <p className="font-weight-bold">Cliente:</p>
          <p>{userClient?.cliente}</p>
        </div>
      </div>

      <ButtonFooter />
    </ModalCustom>
  )
}

export default ModalReadUserClient
