import React from 'react'
import { Modal } from 'react-bootstrap'

const ModalCustom = (props) => {
  return (
    <Modal show={props.show} centered size={props.size}>
      <Modal.Header>
        <Modal.Title className="text-center">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>{props.footer}</Modal.Footer>
    </Modal>
  )
}

export default ModalCustom
