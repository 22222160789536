import React from 'react'
import Button from 'react-bootstrap/Button'

//api
import { deleteEditorial } from '../../../../api/products'
//components
import ModalCustom from '../../../../components/modal'

const ModalDeleteEditorial = ({ url, isOpen, setIsOpen, getEditorials }) => {
  const handleClose = () => {
    setIsOpen(false)
  }

  const editorialDelete = async () => {
    const { status } = await deleteEditorial(url)
    if (status === 204) {
      getEditorials()
      setIsOpen(false)
    }
  }

  const Buttonfooter = () => {
    return (
      <div className="text-center">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cancelar
        </Button>
        <Button variant="secondary" onClick={editorialDelete}>
          Aceptar
        </Button>
      </div>
    )
  }

  return (
    <>
      <ModalCustom show={isOpen}>
        <h5 className="text-center">¿Está seguro de eliminar?</h5>
        <Buttonfooter />
      </ModalCustom>
    </>
  )
}

export default ModalDeleteEditorial
