import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import { userSelector } from '../slices/user'
import { useSelector } from 'react-redux'

// layouts
import DefaultLayout from '../layouts/defaultLayout'

//helpers
import { getItemStore } from '../helpers'

// pages
import HomePage from '../pages/home'
import ListPage from '../pages/list'
import BookDetail from '../pages/detail/index'
import OrderDetail from '../pages/orders/detail'
import CoffeeShopPage from '../pages/cafeteria'
import GalleryPage from '../pages/gallery'
import EventsPage from '../pages/event'
import EventDetailPage from '../pages/event/detail'
import ProfilePage from '../pages/profile'
import Cart from '../pages/cart'
import OkPayment from '../pages/cart/ok_payment'
import ErrorPayment from '../pages/cart/error_payment'
import DynamicFilter from '../pages/dynamicFilter/'
import Nosotros from '../pages/Nosotros/Nosotros'
import Legals from '../pages/legals'
import Refund from '../pages/refund'
import Conditions from '../pages/conditions'
import SearchAdvanced from '../pages/searchAdvanced/'
import FavoritesPage from '../pages/favorites'
import Pedidos from '../pages/orders'
import { PostList } from '../pages/blog'
import Post from '../pages/blog/post'
import RecoverPage from '../pages/recover'
import FeaturedMattersPage from '../pages/matters/featuredMatters'
import Cafeteria from '../pages/cafeteria'
import NotFound from '../pages/404'

//intranet
import Intranet from '../pages/intranet'
import IntranetLayout from '../layouts/intranetLayout'
import Clientes from '../pages/intranet/Clientes'

import Productos from '../pages/intranet/Productos'
import Autores from '../pages/intranet/Autores'
import { Pedidos as PedidosIntranet } from '../pages/intranet/Pedidos'
import Suscriptores from '../pages/intranet/Suscriptores'
import GestionarPosts from '../pages/intranet/GestionarPosts'
import SliderClientes from '../pages/intranet/SliderClientes'
import Galeria from '../pages/intranet/Galeria'
import Merchandising from '../pages/intranet/Merchandising'
import Slider from '../pages/intranet/Slider'
import Listas from '../pages/intranet/Listas'
import Editoriales from '../pages/intranet/Editoriales'
import PreparacionPedidos from '../pages/intranet/PreparacionPedidos'
import EditClient from '../pages/intranet/Clientes/actualizar'
import Budget from '../pages/budget'
import BudgetList from '../pages/budgetList'
import CreateClient from '../pages/intranet/Clientes/crear'
import ModifyBudget from '../pages/modifyBudget'

const DefaultRoute = ({ component: Component, ...rest }) => {
  const { isLogged } = useSelector(userSelector)

  return (
    <Route
      {...rest}
      render={(props) =>
        rest.protected ? (
          !isLogged ? (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          ) : (
            <DefaultLayout>
              {' '}
              <Component {...props} />{' '}
            </DefaultLayout>
          )
        ) : (
          <DefaultLayout>
            {' '}
            <Component {...props} />{' '}
          </DefaultLayout>
        )
      }
    />
  )
}
const IntranetRoute = ({ component: Component, ...rest }) => {
  const typeUser = getItemStore('type_user')
  const { isLogged } = useSelector(userSelector)

  return (
    <Route
      {...rest}
      render={(props) =>
        rest.protected ? (
          !isLogged ? (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          ) : typeUser !== 'trabajador' ? (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          ) : (
            <IntranetLayout>
              <Component {...props} />
            </IntranetLayout>
          )
        ) : (
          <IntranetLayout>
            <Component {...props} />
          </IntranetLayout>
        )
      }
    />
  )
}

const MainRoute = () => {
  const intranetsubRoutes = [
    {
      path: '/intranet/clientes',
      component: Clientes,
    },
    {
      path: '/intranet/clientes/crear',
      component: CreateClient,
    },
    {
      path: '/intranet/clientes/actualizar/:id',
      component: EditClient,
    },
    {
      path: '/intranet/productos',
      component: Productos,
    },
    {
      path: '/intranet/autores',
      component: Autores,
    },
    {
      path: '/intranet/pedidos',
      component: Pedidos,
    },
    {
      path: '/intranet/suscriptores',
      component: Suscriptores,
    },
    {
      path: '/intranet/gestionarposts',
      component: GestionarPosts,
    },
    {
      path: '/intranet/sliderClientes',
      component: SliderClientes,
    },
    {
      path: '/intranet/galeria',
      component: Galeria,
    },
    {
      path: '/intranet/merchandising',
      component: Merchandising,
    },
    {
      path: '/intranet/slider',
      component: Slider,
    },
    {
      path: '/intranet/listas',
      component: Listas,
    },
    {
      path: '/intranet/editoriales',
      component: Editoriales,
    },
    {
      path: '/intranet/preparacionPedidos',
      component: PreparacionPedidos,
    },
  ]

  return (
    <Router>
      <Switch>
        <DefaultRoute exact path="/" component={HomePage} />
        <DefaultRoute exact path="/lista/:nombre" component={ListPage} />
        <DefaultRoute exact path="/materia/:matterName" component={ListPage} />
        <DefaultRoute exact path="/autor/:slug" component={ListPage} />
        <DefaultRoute exact path="/editorial/:slug" component={ListPage} />
        <DefaultRoute
          exact
          path="/materias-destacadas"
          component={FeaturedMattersPage}
        />
        <DefaultRoute exact path="/cafeteria/" component={CoffeeShopPage} />
        <DefaultRoute exact path="/galeria" component={GalleryPage} />
        <DefaultRoute exact path="/eventos" component={EventsPage} />
        <DefaultRoute exact path="/eventos/:id" component={EventDetailPage} />
        <DefaultRoute exact path="/carrito" component={Cart} />
        <DefaultRoute exact path="/pago-ok" component={OkPayment} />
        <DefaultRoute exact path="/pago-error" component={ErrorPayment} />
        <DefaultRoute
          exact
          path="/perfil"
          protected={true}
          component={ProfilePage}
        />
        <DefaultRoute
          exact
          path="/presupuestos"
          protected={true}
          component={BudgetList}
        />
        <DefaultRoute exact path="/filtro/:type/" component={DynamicFilter} />
        <DefaultRoute
          exact
          path="/productos/:type/:url/"
          component={BookDetail}
        />
        <DefaultRoute exact path="/nosotros" component={Nosotros} />
        <DefaultRoute exact path="/aviso-legal" component={Legals} />
        <DefaultRoute
          exact
          path="/devoluciones-y-anulaciones"
          component={Refund}
        />
        <DefaultRoute exact path="/condiciones" component={Conditions} />
        <DefaultRoute
          exact
          path="/buscador-avanzado"
          component={SearchAdvanced}
        />
        <DefaultRoute exact path="/favoritos" component={FavoritesPage} />
        <DefaultRoute exact path="/blog" component={PostList} />
        <DefaultRoute
          exact
          path="/blog/post/:year/:month/:day/:slug"
          component={Post}
        />
        <DefaultRoute
          exact
          path="/recuperar-contrasena/:uid/:token"
          component={RecoverPage}
        />
        <DefaultRoute
          exact
          protected={true}
          path="/mis-pedidos"
          component={Pedidos}
        />
        <DefaultRoute
          exact
          protected={true}
          path="/mis-pedidos/:pk/"
          component={OrderDetail}
        />
        <DefaultRoute
          exact
          protected={false}
          path="/cafeteria"
          component={Cafeteria}
        />

        <DefaultRoute
          exact
          protected={false}
          path="/presupuesto"
          component={Budget}
        />

        <DefaultRoute
          exact
          protected={false}
          path="/editarpresupuesto/:id"
          component={ModifyBudget}
        />

        <IntranetRoute
          exact
          protected={true}
          path="/intranet"
          component={Intranet}
        />

        {intranetsubRoutes.map(({ path, component }, index) => (
          <IntranetRoute
            key={`route${path}#${index}`}
            exact
            protectred
            {...{ path, component }}
          />
        ))}

        <DefaultRoute path="*" protected={false} component={NotFound} />
      </Switch>
    </Router>
  )
}

export default MainRoute
