import React from 'react'

const CustomInput = ({
  handleSubmit,
  onChange,
  inputValue = '',
  placeholder,
  icon,
  label,
  type,
  readOnly,
  disabled,
}) => (
  <div>
    <form className="d-flex">
      <div className="input-group">
        <div className="form-outline w-100">
          {label && <label className="m-3">{label}</label>}
          <input
            disabled={disabled}
            readOnly={readOnly}
            type={type}
            id="form1"
            className="form-control input-search "
            value={inputValue}
            onChange={onChange}
            placeholder={placeholder}
          />
        </div>
      </div>
      {icon && (
        <div
          type="button"
          className="btn button-search "
          onClick={handleSubmit}
        >
          {icon}
        </div>
      )}
    </form>
  </div>
)

export default CustomInput
