import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

//icons
import { FaEdit, FaEye, FaEyeSlash } from 'react-icons/fa'
import { AiFillDelete } from 'react-icons/ai'
import { BsSearch } from 'react-icons/bs'

//api
import { getPosts } from '../../../api/blog'

//custom hook
import { useQuery } from '../../../utils/customHooks/query'

//components
import CustomInput from '../../../components/customInput'
import IntranetPagination from '../Pagination'
// import ModalDeleteEditorial from './modalDelete'
import CustomTable from '../../../components/table'
import ModalEditPost from './modalEdit'

const GestionarPost = () => {
  const history = useHistory()
  const [listsName, setListsName] = useState([])
  const searchParams = useQuery()

  const page = searchParams.get('page') || 1
  const pageSize = 10
  const [inputBody, setInputBody] = useState('')
  const [inputTitle, setInputTitle] = useState('')
  const [inputAuthor, setInputAuthor] = useState('')
  const [noResults, setNoResults] = useState(false)
  const [totalPages, setTotalPages] = useState(null)
  const [postsList, setPostsList] = useState({})
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [dataPost, setDataPost] = useState()
  const [openModal, setOpenModal] = useState(false)
  // const [isOpenModal, setIsOpenModal] = useState(false)

  const query = {
    title: inputTitle,
    body: inputBody,
    author: inputAuthor,
    page: page,
    page_size: 10,
  }

  const searchQuery = new URLSearchParams(query)
  const queryString = searchQuery.toString()

  const getPostsList = async () => {
    const { data, status } = await getPosts()
    const { results } = data
    if (status === 200) {
      setListsName(results)
      setTotalPages(Math.ceil(data.count / pageSize))
      setPostsList(results)
    }
  }

  const searchByTitle = (e) => {
    setInputTitle(e.target.value)
  }
  const searchByBody = (e) => {
    setInputBody(e.target.value)
  }
  const searchByAuthor = (e) => {
    setInputAuthor(e.target.value)
  }

  const handleSubmit = async (e) => {
    try {
      const { data } = await getPosts(queryString)
      console.log('data', data)
      if (data.results.length > 0) {
        setListsName(data?.results)
        setNoResults(false)
        setTotalPages(Math.ceil(data.count / pageSize))
      } else {
        setListsName([])
        setNoResults(true)
      }
    } catch (error) {}
    history.push(`?${queryString}`)
    setIsActiveSearch(true)
  }

  useEffect(() => {
    getPostsList()
  }, [])

  useEffect(() => {
    if (inputTitle || inputBody || inputAuthor !== '') {
      handleSubmit()
    } else {
      getPostsList()
    }
  }, [page])

  const handleClear = () => {
    setInputTitle('')
    setInputBody('')
    setInputAuthor('')
    history.push('/intranet/gestionarPosts')
  }

  const openIsModal = (item, id, setOpenModal) => {
    if (item.id === id) {
      setOpenModal(true)
      setDataPost(item)
    }
  }

  const colsEditorials = [
    'Título',
    'Autor',
    'Fecha Publicación',
    'Fecha Creación',
    'Acciones',
  ]

  return (
    <div>
      <h1>Gestionar Posts</h1>
      <div className="d-flex justify-content-end m-3">
        <CustomInput
          handleSubmit={handleSubmit}
          onChange={(e) => searchByTitle(e)}
          inputValue={inputTitle}
          placeholder="Buscar por titulo"
          icon={<BsSearch color="white" />}
          type="search"
        />
        <CustomInput
          handleSubmit={handleSubmit}
          onChange={(e) => searchByBody(e)}
          inputValue={inputBody}
          placeholder="Buscar por cuerpo"
          icon={<BsSearch color="white" />}
          type="search"
        />
        <CustomInput
          handleSubmit={handleSubmit}
          onChange={(e) => searchByAuthor(e)}
          inputValue={inputAuthor}
          placeholder="Buscar por autor"
          icon={<BsSearch color="white" />}
          type="search"
        />
        <button onClick={handleClear}>Limpiar url</button>
      </div>
      <CustomTable cols={colsEditorials} noResults={noResults}>
        {listsName.map((listName) => {
          return (
            <tr key={listName.id}>
              <td className="text-center">{listName.title}</td>
              <td className="text-center">{listName.author}</td>
              <td className="text-center">{listName.publish_time}</td>
              <td className="text-center">{listName.publish_date}</td>
              <td className="text-center">
                <FaEdit
                  className="mr-3 cursor-pointer"
                  onClick={() =>
                    openIsModal(listName, listName.id, setOpenModal)
                  }
                />
                {listName.status === 'published' ? (
                  <FaEye className="mr-3 cursor-pointer" />
                ) : (
                  <FaEyeSlash className="mr-3 cursor-pointer" />
                )}
                <AiFillDelete
                  className="cursor-pointer"
                  // onClick={() =>
                  //   openIsModal(listName, listName.id, setIsOpenModal)
                  // }
                />
              </td>
            </tr>
          )
        })}
      </CustomTable>
      <ModalEditPost
        dataPost={dataPost}
        isModal={openModal}
        setIsModal={setOpenModal}
        getEditorials={getPosts}
      />
      {/* <ModalDeleteEditorial
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        getEditorials={getEditorials}
        url={dataEditorial?.url}
      /> */}
      <IntranetPagination
        page={parseInt(page)}
        pages={totalPages}
        isActiveSearch={isActiveSearch}
        queryParams={queryString}
      />
    </div>
  )
}
export default GestionarPost
