import React, { useState, useEffect } from 'react'
import { FaEdit } from 'react-icons/fa'
import { AiFillDelete } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import { BsSearch } from 'react-icons/bs'
import Button from 'react-bootstrap/Button'

//api
import { getListAuthors } from '../../../api/authors'
//customHook
import { useQuery } from '../../../utils/customHooks/query'
//components
import IntranetPagination from '../Pagination'
import ModalEditNameAuthor from './modalEdit/index'
import CustomInput from '../../../components/customInput'
import CustomTable from '../../../components/table'
import ModalDeleteAuthor from './modalDelete/index'
import ModalCreateAuthor from './modalCreate/index'

const Autores = () => {
  const history = useHistory()
  const [listNames, setListNames] = useState([])
  const searchParams = useQuery()
  //const searchTerm = searchParams.get('inputValue') || ''
  const page = searchParams.get('page') || 1
  const pageSize = 10
  const [inputValue, setInputValue] = useState('')
  const [noResults, setNoResults] = useState(false)
  const [totalPages, setTotalPages] = useState(null)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [dataAuthor, setDataAuthor] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [openModalCreateAuthor, setOpenModalCreateAuthor] = useState(false)

  const query = {
    nombre_autor: inputValue,
    page: page,
    page_size: pageSize,
  }
  const searchQuery = new URLSearchParams(query)
  const queryString = searchQuery.toString()

  const getNameAuthors = async () => {
    const { data, status } = await getListAuthors(queryString)
    const { results } = data
    if (status === 200) {
      setListNames(results)
      setTotalPages(Math.ceil(data.count / pageSize))
    }
  }

  const handleValue = (e) => {
    setInputValue(e.target.value)
  }

  const handleSubmit = async () => {
    try {
      const { data } = await getListAuthors(queryString)

      if (data.results.length > 0) {
        setListNames(data?.results)
        setNoResults(false)
        setTotalPages(Math.ceil(data.count / pageSize))
      } else {
        setListNames([])
        setNoResults(true)
      }
    } catch (error) {}
    history.push(`?${queryString}`)
    setIsActiveSearch(true)
  }

  useEffect(() => {
    getNameAuthors()
  }, [])

  useEffect(() => {
    if (inputValue !== '') {
      handleSubmit()
    } else {
      getNameAuthors()
    }
  }, [page])

  const handleClear = () => {
    setInputValue('')
    setNoResults(false)
    history.push('/intranet/autores')
  }

  const openIsModal = (item, id, setOpenModal) => {
    if (item.id === id) {
      setOpenModal(true)
      setDataAuthor(item)
    }
  }

  const colsAuthors = ['Nombre Autor', 'Url', 'Acciones']

  return (
    <div>
      <div className="d-flex mt-3 justify-content-between">
        <h1>Autores</h1>
        <Button
          variant="secondary"
          type="submit"
          className="height-39 mr-4 "
          onClick={() => setOpenModalCreateAuthor(true)}
        >
          Crear autor
        </Button>
      </div>

      <div className="d-flex justify-content-end m-3">
        <CustomInput
          handleSubmit={(e) => handleSubmit(e)}
          onChange={handleValue}
          inputValue={inputValue}
          placeholder="Buscar por nombre de autor"
          icon={<BsSearch color="white" />}
          type={'search'}
        />
        <button onClick={handleClear}>Limpiar url</button>
      </div>
      <CustomTable cols={colsAuthors} noResults={noResults}>
        {listNames &&
          listNames.map((listName, index) => {
            return (
              <tr key={index}>
                <td className="text-center">{listName.nombre_autor}</td>
                <td className="text-center">{listName.url}</td>
                <td className="text-center">
                  <FaEdit
                    className="mr-5 cursor-pointer"
                    onClick={() =>
                      openIsModal(listName, listName.id, setOpenModal)
                    }
                  />
                  <AiFillDelete
                    onClick={() =>
                      openIsModal(listName, listName.id, setIsOpenModal)
                    }
                    className="cursor-pointer"
                  />
                </td>
              </tr>
            )
          })}
      </CustomTable>
      <ModalCreateAuthor
        url={dataAuthor}
        isModal={openModalCreateAuthor}
        setIsModal={setOpenModalCreateAuthor}
        getNameAuthors={getNameAuthors}
      />
      <ModalEditNameAuthor
        dataAuthor={dataAuthor}
        isModal={openModal}
        setIsModal={setOpenModal}
        getNameAuthors={getNameAuthors}
      />
      <ModalDeleteAuthor
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        getNameAuthors={getNameAuthors}
        url={dataAuthor?.url}
      />
      <IntranetPagination
        page={parseInt(page)}
        pages={totalPages}
        isActiveSearch={isActiveSearch}
        queryParams={queryString}
      />
    </div>
  )
}

export default Autores
