export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 992 },
    items: 3,
    partialVisibilityGutter: 80,
  },
  tablet: {
    breakpoint: { max: 991, min: 768 },
    items: 4,
    partialVisibilityGutter: 70,
  },
  mobile: {
    breakpoint: { max: 768, min: 200 },
    items: 2,
  },
}

export const API_INFO = process.env.REACT_APP_API_INFO
