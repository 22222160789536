import React from 'react'
import Button from 'react-bootstrap/Button'
//api
import { deleteAuthor } from '../../../../api/authors'
//components
import ModalCustom from '../../../../components/modal/index'

const ModalDeleteAuthor = ({ url, isOpen, setIsOpen, getNameAuthors }) => {
  const handleClose = () => {
    setIsOpen(false)
  }

  const authorDelete = async () => {
    const { status } = await deleteAuthor(url)
    if (status === 204) {
      getNameAuthors()
      setIsOpen(false)
    }
  }

  const Buttonfooter = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cancelar
        </Button>
        <Button variant="secondary" onClick={authorDelete}>
          Aceptar
        </Button>
      </div>
    )
  }

  return (
    <>
      <ModalCustom
        show={isOpen}
        title={<h5 className="text-center">¿Está seguro de eliminar?</h5>}
      >
        <Buttonfooter />
      </ModalCustom>
    </>
  )
}

export default ModalDeleteAuthor
