import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'

//api
import { editNameEditorial } from '../../../../api/products'

//components
import ModalCustom from '../../../../components/modal'
import CustomInput from '../../../../components/customInput'

const ModalEditEditorial = ({
  dataEditorial,
  isModal,
  setIsModal,
  getEditorials,
}) => {
  const [nameEditorial, setNameEditorial] = useState('')

  useEffect(() => {
    setNameEditorial(dataEditorial?.nombre_fiscal)
  }, [dataEditorial?.nombre_fiscal])

  const data = {
    nombre_fiscal: nameEditorial,
    url: dataEditorial?.url,
  }
  const url = dataEditorial?.url

  const updateNameEditorial = async () => {
    const { status } = await editNameEditorial(url, data)
    if (status === 200) {
      getEditorials()
      setIsModal(false)
    } else {
      setIsModal(true)
    }
    setNameEditorial('')
  }

  const handleClose = () => {
    setIsModal(false)
  }
  const handleValue = (e) => {
    setNameEditorial(e.target.value)
  }

  const Buttonfooter = () => {
    return (
      <div className="text-center">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cancelar
        </Button>
        <Button variant="secondary" onClick={updateNameEditorial}>
          Aceptar
        </Button>
      </div>
    )
  }

  return (
    <ModalCustom
      show={isModal}
      title={<p className="text-center">Modificar Editorial</p>}
    >
      <div className="margin-bottom-30">
        <CustomInput
          onChange={handleValue}
          inputValue={nameEditorial}
          type={'text'}
          label={'Nombre de editorial'}
        />
      </div>
      <Buttonfooter />
    </ModalCustom>
  )
}

export default ModalEditEditorial
