import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Toast,
} from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { TiDelete, TiPlus, TiMinus } from 'react-icons/ti'
import { useHistory } from 'react-router-dom'

//api
import { getLibraries } from '../../api/products'
import { getListClients } from '../../api/clients'
import { editBudget, getBudget, getVisits } from '../../api/ecommerce'

//componentes
import CustomTable from '../../components/table'

const ModifyBudget = (props) => {
  const history = useHistory()
  const [descuento, setDescuento] = useState(0)
  const [observaciones, setObservaciones] = useState('')
  const [nombre, setNombre] = useState('')
  const [budgetQuery, setBudgetQuery] = useState({})
  const [budgetId, setBudgetId] = useState('')
  const [isBudgetSaved, setIsBudgetSaved] = useState(true)
  const [itemToEdit, setItemToEdit] = useState({})
  const [itemsArray, setItemsArray] = useState(itemToEdit.details || [])
  const [isLoading, setIsLoading] = useState(false)
  const [libraryName, setLibraryName] = useState('')
  const [clientName, setClientName] = useState('')
  const [showToast, setShowToast] = useState(false)

  const toggleToast = () => {
    setShowToast(!showToast)
  }

  const popoverAdd = <Popover className="p-3">Se añadio un producto</Popover>
  const popoverRemove = <Popover className="p-3">Se quito un producto</Popover>
  const popoverDelete = (
    <Popover className="p-3">Se elimino un producto</Popover>
  )

  const getBudgetToEdit = async () => {
    setIsLoading(true)
    const { status, data } = await getBudget(props.match.params.id)
    if (status === 200) {
      setLibraryName(data.library_name)
      setClientName(data.client_name)
      setBudgetQuery({
        nombre: data.nombre,
        biblioteca: data.biblioteca,
        cliente: data.cliente,
        observaciones: data.observaciones,
        visita: data.visita,
        details: data.details,
        descuento: data.descuento,
      })
      setIsLoading(false)
      setItemToEdit(data)
      setItemsArray(data.details)
    } else {
      console.log('error to get item to edit')
    }
  }

  useEffect(() => {
    getBudgetToEdit()
  }, [])

  const increaseQuantityItem = (id) => {
    const newItemsArray = itemsArray.map((item) => {
      if (item.id === id) {
        return { ...item, cantidad: item.cantidad + 1 }
      }
      return item
    })
    setItemsArray(newItemsArray)
    setBudgetQuery({ ...budgetQuery, details: newItemsArray })
  }

  const decreaseQuantityItem = (id) => {
    const newItemsArray = itemsArray.map((item) => {
      if (item.id === id) {
        return { ...item, cantidad: item.cantidad - 1 }
      }
      return item
    })
    setItemsArray(newItemsArray)
    setBudgetQuery({ ...budgetQuery, details: newItemsArray })
  }

  const removeProductCart = (index) => {
    const newItemsArray = [...itemsArray]
    newItemsArray.splice(index, 1)
    setItemsArray(newItemsArray)
    setBudgetQuery({ ...budgetQuery, details: newItemsArray })
  }

  const editBudgetFunc = async () => {
    const { status, data } = await editBudget(itemToEdit.id, budgetQuery)
    if (status === 200) {
      setIsBudgetSaved(false)
      setBudgetId(data.id)
      toggleToast()
      setTimeout(() => {
        history.push('/presupuestos')
      }, 3000)
    } else {
      console.log('error al modificar el presupuesto!')
    }
  }

  const handleNombre = (e) => {
    setNombre(e.target.value)
    setBudgetQuery({ ...budgetQuery, nombre: e.target.value })
  }

  const handleDescuento = (e) => {
    setDescuento(parseFloat(e.target.value))
    setBudgetQuery({ ...budgetQuery, descuento: e.target.value + '%' })
  }

  const handleObs = (e) => {
    setObservaciones(e.target.value)
    setBudgetQuery({ ...budgetQuery, observaciones: e.target.value })
  }

  const [clientesOptions, setClientesOptions] = useState([])
  const [isLoadingClientes, setIsLoadingClientes] = useState(false)
  const [cliente, setCliente] = useState({})
  const getClientsFunc = async (query) => {
    setIsLoadingClientes(true)
    const { status, data } = await getListClients(`nombre_fiscal=${query}`)
    if (status === 200) {
      setClientesOptions(data.results)
      setIsLoadingClientes(false)
    }
  }
  const handleCliente = (selected) => {
    setCliente(selected[0])
    setBudgetQuery({ ...budgetQuery, cliente: selected[0].id })
  }

  const [visitaOptions, setVisitaOptions] = useState([])
  const [isLoadingVisita, setIsLoadingVisita] = useState(false)
  const [visita, setVisita] = useState({})
  const getVisitaFunc = async (query) => {
    setIsLoadingVisita(true)
    const { status, data } = await getVisits(`${query}`)
    if (status === 200) {
      setVisitaOptions(data.results)
      setIsLoadingVisita(false)
    } else {
      setBudgetQuery({ ...budgetQuery, visita: '' })
    }
  }

  const handleVisita = (selected) => {
    setVisita(selected[0])
  }

  const [bibliotecaOptions, setBibliotecaOptions] = useState([])
  const [isLoadingBiblioteca, setIsLoadingBiblioteca] = useState(false)
  const [biblioteca, setBiblioteca] = useState({})
  const getBibliotecaFunc = async (query) => {
    setIsLoadingBiblioteca(true)
    const { status, data } = await getLibraries(`denominacion=${query}`)
    if (status === 200) {
      setBibliotecaOptions(data.results)
      setIsLoadingBiblioteca(false)
    }
  }
  const handleBiblioteca = (selected) => {
    setBiblioteca(selected[0])
    setBudgetQuery({ ...budgetQuery, biblioteca: selected[0].id })
  }

  let totalItems = 0
  for (let i = 0; i < itemsArray.length; i++) {
    totalItems += itemsArray[i].cantidad
  }

  let totalPrice = 0
  for (let i = 0; i < itemsArray.length; i++) {
    totalPrice +=
      itemsArray[i].cantidad * parseFloat(itemsArray[i].price).toFixed(2)
  }

  const filterBy = () => true

  const [validated, setValidated] = useState(false)

  const handleSubmit = async (event) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === true) {
      setValidated(false)
    } else {
      setValidated(true)
    }
  }

  const colsTable = [
    'EAN o ID',
    'Nombre',
    'Tipo',
    'Cantidad',
    'Acciones',
    'P. Normal',
    'Total',
  ]

  return (
    <Container>
      <h1>Editar presupuesto</h1>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" size="lg" />
        </div>
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formNombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Nombre presupuesto"
                  onChange={handleNombre}
                  defaultValue={itemToEdit.nombre}
                />
                <Form.Control.Feedback type="invalid">
                  Este campo es obligatorio.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formClientes">
                <Form.Label>Elegir Cliente</Form.Label>
                <AsyncTypeahead
                  style={{ width: '20rem' }}
                  filterBy={filterBy}
                  id="client-search"
                  isLoading={isLoadingClientes}
                  labelKey="nombre_fiscal"
                  minLength={3}
                  onSearch={getClientsFunc}
                  onChange={handleCliente}
                  options={clientesOptions}
                  placeholder="Buscar Cliente"
                  // defaultInputValue={itemToEdit.client_name}
                  defaultInputValue={clientName}
                  renderMenuItemChildren={(option) => (
                    <span>{option.nombre_fiscal}</span>
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="biblioteca">
                <Form.Label>Elegir Biblioteca</Form.Label>
                <AsyncTypeahead
                  style={{ width: '20rem' }}
                  filterBy={filterBy}
                  id="biblioteca-search"
                  isLoading={isLoadingBiblioteca}
                  labelKey="denominacion"
                  minLength={3}
                  onSearch={getBibliotecaFunc}
                  onChange={handleBiblioteca}
                  options={bibliotecaOptions}
                  placeholder="Buscar Cliente"
                  // defaultInputValue={itemToEdit.library_name}
                  defaultInputValue={libraryName}
                  renderMenuItemChildren={(option) => (
                    <span>{option.denominacion}</span>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="visita">
                <Form.Label>Elegir Visita</Form.Label>
                <AsyncTypeahead
                  style={{ width: '20rem' }}
                  filterBy={filterBy}
                  id="visita-search"
                  isLoading={isLoadingVisita}
                  labelKey="visita"
                  minLength={3}
                  onSearch={getVisitaFunc}
                  onChange={handleVisita}
                  options={visitaOptions}
                  placeholder="Buscar Visita"
                  defaultInputValue={itemToEdit.visita}
                  // renderMenuItemChildren={(option) => (
                  //   <span>{option}</span>
                  // )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formObs">
                <Form.Label>Observaciones</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese Observaciones"
                  onChange={handleObs}
                  defaultValue={itemToEdit.observaciones}
                />
              </Form.Group>
              <Form.Control.Feedback type="invalid">
                Este campo no debe exceder los 100 caracteres.
              </Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formDescuento">
                <Form.Label>Agregar descuento en %</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Ingrese monto del descuento"
                  defaultValue={itemToEdit.descuento}
                  value={descuento}
                  onChange={handleDescuento}
                  min="0"
                  max="100"
                  step="0.01"
                />
                <Form.Control.Feedback type="invalid">
                  Este campo debe ser un número decimal entre 0 y 100.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <CustomTable cols={colsTable}>
              {itemsArray.map((items) => {
                return (
                  <tr key={items.id}>
                    <td className="text-center">
                      {Number(items.ean) || items.id}
                    </td>
                    <td className="text-center">{items.name}</td>
                    <td className="text-center">{items.type}</td>
                    <td className="text-center">{items.cantidad}</td>
                    <td className="text-center">
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={popoverAdd}
                        rootClose={true}
                      >
                        <TiPlus
                          size="1.2rem"
                          className="mr-1"
                          onClick={() => increaseQuantityItem(items.id)}
                        />
                      </OverlayTrigger>

                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={popoverRemove}
                        rootClose={true}
                      >
                        <TiMinus
                          size="1.2rem"
                          className="mr-1"
                          onClick={() => decreaseQuantityItem(items.id)}
                        />
                      </OverlayTrigger>

                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={popoverDelete}
                        rootClose={true}
                      >
                        <TiDelete
                          size="1.2rem"
                          onClick={() => removeProductCart(items)}
                        />
                      </OverlayTrigger>
                    </td>
                    <td className="text-center">{items.price.toFixed(2)}</td>
                    <td className="text-center">
                      {(items.price * items.cantidad).toFixed(2)}
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td className="text-center">
                  <strong>Totales</strong>
                </td>
                <td></td>
                <td></td>
                <td className="text-center">
                  <strong>{totalItems}</strong>
                </td>
                <td></td>
                <td></td>
                <td className="text-center">
                  <strong>
                    {(descuento || 0) === 0
                      ? totalPrice.toFixed(2)
                      : (totalPrice * (1 - descuento / 100)).toFixed(2)}
                  </strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-center">
                  <Button
                    size="sm"
                    onClick={() => editBudgetFunc()}
                    type="submit"
                  >
                    Guardar presupuesto
                  </Button>
                </td>
              </tr>
            </CustomTable>
          </Row>
        </Form>
      )}
      <Toast show={showToast} onClose={toggleToast}>
        <Toast.Header>
          <strong>Presupuesto modificado con exito</strong>
        </Toast.Header>
        <Toast.Body>Sera redireccionado a la lista de presupuestos</Toast.Body>
      </Toast>
    </Container>
  )
}

export default ModifyBudget
