import React, { useState, useEffect } from 'react'
import { FaEdit, FaBook } from 'react-icons/fa'
import { AiFillDelete, AiFillCheckSquare } from 'react-icons/ai'
import { ImCheckboxUnchecked } from 'react-icons/im'
import { useHistory } from 'react-router-dom'
import { BsSearch } from 'react-icons/bs'

//api
import { getSlider } from '../../../api/products'
//customHook
import { useQuery } from '../../../utils/customHooks/query'
//components
// import IntranetPagination from '../Pagination'
// import ModalEditNameAuthor from './modalEdit/index'
// import CustomInput from '../../../components/customInput'
import CustomTable from '../../../components/table'
import { Button, Form } from 'react-bootstrap'
import ModalEditSlider from './modalEdit'
import ModalCreateSlider from './modalCreate'
// import ModalDeleteAuthor from './modalDelete/index'

const Slider = () => {
  // const history = useHistory()
  const [listsSlider, setListsSlider] = useState([])
  const searchParams = useQuery()
  //const searchTerm = searchParams.get('inputValue') || ''
  const page = searchParams.get('page') || 1
  const pageSize = 10
  const [inputValue, setInputValue] = useState('')
  const [noResults, setNoResults] = useState(false)
  // const [totalPages, setTotalPages] = useState(null)
  // const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [dataSlider, setDataSlider] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [createOpenModal, setCreateOpenModal] = useState(false)

  // const query = {
  //   nombre_autor: inputValue,
  //   page: page,
  //   page_size: pageSize,
  // }

  // const searchQuery = new URLSearchParams(query)
  // const queryString = searchQuery.toString()

  const getSliders = async () => {
    const { data, status } = await getSlider()
    if (status === 200) {
      setListsSlider(data)
      // setTotalPages(Math.ceil(data.count / pageSize))
    }
  }

  // const handleValue = (e) => {
  //   setInputValue(e.target.value)
  // }

  // const handleSubmit = async () => {
  //   try {
  //     const { data } = await getListAuthors(queryString)
  //     if (data.results.length > 0) {
  //       setListsName(data?.results)
  //       setNoResults(false)
  //       setTotalPages(Math.ceil(data.count / pageSize))
  //     } else {
  //       setListsName([])
  //       setNoResults(true)
  //     }
  //   } catch (error) {}
  //   history.push(`?${queryString}`)
  //   setIsActiveSearch(true)
  // }

  useEffect(() => {
    getSliders()
  }, [])

  useEffect(() => {
    if (inputValue !== '') {
      // handleSubmit()
    } else {
      getSliders()
    }
  }, [page])

  // const handleClear = () => {
  //   setInputValue('')
  //   history.push('/intranet/autores')
  // }

  const openIsModal = (item, id, setOpenModal) => {
    if (item.id === id) {
      setOpenModal(true)
      setDataSlider(item)
    }
  }

  const createModal = () => {
    setCreateOpenModal(true)
  }

  const colsAuthors = ['Tipo', 'Producto', 'Fecha', 'Activo', 'Operaciones']

  return (
    <div>
      <h1>Sliders</h1>
      <div className="mb-3 d-flex flex-row-reverse">
        <Button onClick={createModal} variant="secondary">
          Crear Elemento Slider
        </Button>
      </div>
      {/* <div className="d-flex justify-content-end m-3">
        <CustomInput
          handleSubmit={(e) => handleSubmit(e)}
          onChange={handleValue}
          inputValue={inputValue}
          placeholder="Buscar por nombre de autor"
          icon={<BsSearch color="white" />}
          type={'search'}
        />
        <button onClick={handleClear}>Limpiar url</button>
      </div> */}
      <CustomTable cols={colsAuthors} noResults={noResults}>
        {listsSlider &&
          listsSlider.map((listName) => {
            return (
              <tr key={listName.id}>
                <td className="text-center">{listName.tipo}</td>
                <td className="text-center">{listName.producto.titulo}</td>
                <td className="text-center">
                  {new Date(listName.fecha).toLocaleDateString('es-ES')}
                </td>
                <td className="text-center">
                  {listName.estado == true ? (
                    <AiFillCheckSquare />
                  ) : (
                    <ImCheckboxUnchecked />
                  )}
                </td>
                <td className="text-center">
                  <FaBook
                    className="mr-3 cursor-pointer"
                    // onClick={() =>
                    //   openIsModal(listName, listName.id, setOpenModal)
                    // }
                  />
                  <FaEdit
                    className="mr-3 cursor-pointer"
                    onClick={() =>
                      openIsModal(listName, listName.id, setOpenModal)
                    }
                  />
                  <AiFillDelete
                    // onClick={() =>
                    //   openIsModal(listName, listName.id, setIsOpenModal)
                    // }
                    className="mr-3 cursor-pointer"
                  />
                </td>
              </tr>
            )
          })}
      </CustomTable>
      <ModalEditSlider
        dataSlider={dataSlider}
        isModal={openModal}
        setIsModal={setOpenModal}
        getSliders={getSliders}
      />
      <ModalCreateSlider
        modal={createOpenModal}
        setCreateModal={setCreateOpenModal}
        getSliders={getSliders}
      />
      {/* <ModalDeleteAuthor
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        getNameAuthors={getNameAuthors}
        url={dataAuthor?.url}
      />
      <IntranetPagination
        page={parseInt(page)}
        pages={totalPages}
        isActiveSearch={isActiveSearch}
        queryParams={queryString}
      /> */}
    </div>
  )
}

export default Slider
