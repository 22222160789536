import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'

//api
import { editNameAuthor } from '../../../../api/authors'
//components
import ModalCustom from '../../../../components/modal/index'
import CustomInput from '../../../../components/customInput'

const ModalEditNameAuthor = ({
  dataAuthor,
  isModal,
  setIsModal,
  getNameAuthors,
}) => {
  const [nameAuthor, setNameAuthor] = useState('')
  const [validate, setValidate] = useState('')

  useEffect(() => {
    setNameAuthor(dataAuthor?.nombre_autor)
  }, [dataAuthor?.nombre_autor])

  const data = {
    nombre_autor: nameAuthor,
    url: dataAuthor?.url,
  }
  const url = dataAuthor?.url

  const updateNameAuthor = async () => {
    const { status } = await editNameAuthor(url, data)

    if (status === 200) {
      getNameAuthors()
      setNameAuthor('')
      setIsModal(false)
    } else {
      setIsModal(true)
    }
    if (nameAuthor === dataAuthor?.nombre_autor && status !== 200) {
      setValidate('Por favor modifica el nombre de autor')
    }
  }

  const handleClose = () => {
    setIsModal(false)
  }
  const handleValue = (e) => {
    setNameAuthor(e.target.value)
  }
  const size = 'md'

  const Buttonfooter = () => {
    return (
      <div className="text-center">
        <Button variant="secondary" onClick={handleClose} className="mr-3">
          Cancelar
        </Button>
        <Button variant="secondary" onClick={updateNameAuthor}>
          Aceptar
        </Button>
      </div>
    )
  }

  return (
    <ModalCustom
      show={isModal}
      title={<p className="text-center">Modificar Autor</p>}
    >
      <div className="margin-bottom-30">
        <CustomInput
          size={size}
          onChange={handleValue}
          inputValue={nameAuthor}
          type={'text'}
          label={'Nombre de autor'}
        />
        {validate && (
          <p className="text-danger">
            <small>{validate}</small>
          </p>
        )}
      </div>
      <Buttonfooter />
    </ModalCustom>
  )
}

export default ModalEditNameAuthor
