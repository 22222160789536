import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  getCurrentPageUrlData,
  createPagination,
} from '../../../utils/pagination'

const IntranetPagination = ({
  page,
  pages,
  queryParams,
  isActiveSearch,
  getData,
}) => {
  page = parseInt(page)
  const pageLimit = 5
  const lowerPageLimit = page - pageLimit > 0 ? page - pageLimit : 1
  const areMorePagesBelowLowerLimit = lowerPageLimit > 1
  const upperPageLimit = page + pageLimit > pages ? pages : page + pageLimit
  const areMorePagesAboveUpperLimit = pages - upperPageLimit > 1
  let iteration = lowerPageLimit
  const pageNumbers = createPagination(iteration, upperPageLimit)

  const currentPageQueryData = page < pages ? page : pages
  const pageSearch = getCurrentPageUrlData(queryParams, pageNumbers).find(
    (item) => item.page === currentPageQueryData
  )
  const validatePageNext = page < pages ? page + 1 : 1

  const changeButtonPagination = (typeButton, typePage) => {
    const validateTypeButton =
      typeButton === 'anterior' ? page - 1 : validatePageNext
    const validateTypePage = typePage === 'start' ? 1 : pages
    const validateTypes =
      typeButton !== null ? validateTypeButton : validateTypePage

    const searchQuery = new URLSearchParams({
      ...pageSearch,
      page: validateTypes,
    })
    const convertToQuery = searchQuery.toString()
    return convertToQuery
  }
  const pathBefore = isActiveSearch
    ? `?${changeButtonPagination('anterior', null)}`
    : `?page=${page - 1}`

  const pathNext = isActiveSearch
    ? `?${changeButtonPagination('siguiente', null)}`
    : `?page=${validatePageNext}`

  const pageStart = isActiveSearch
    ? `?${changeButtonPagination(null, 'start')}`
    : `?page=1`

  const pageEnd = isActiveSearch
    ? `?${changeButtonPagination(null, 'end')}`
    : `?page=${pages}`

  const ConatinerPages = ({ pageNumbers }) => {
    return (
      pageNumbers &&
      pageNumbers.map((pageNumber, index) => {
        const firstLink = index === 0
        const lastLink = index === pageNumbers.length - 1
        const areMorePagesBelow = areMorePagesBelowLowerLimit && firstLink
        const areMorePagesAbove = areMorePagesAboveUpperLimit && lastLink
        const searchQuery = new URLSearchParams({
          ...pageSearch,
          page: pageNumber,
        })
        const queries = searchQuery.toString()
        const path = isActiveSearch ? `?${queries}` : `?page=${pageNumber}`

        return (
          <li
            key={`page#${pageNumber}`}
            className={`page-item ${page === pageNumber ? 'disabled' : ''}`}
          >
            <Link className="page-link" to={path}>
              {areMorePagesAbove || areMorePagesBelow ? '...' : pageNumber}
            </Link>
          </li>
        )
      })
    )
  }

  return (
    <div className="container-fluid mt-1 p-0 ">
      <nav aria-label="Page navigation">
        <ul className="pagination pagination-sm justify-content-between mb-1">
          <li className={`page-item ${page <= 1 ? 'disabled' : ''}`}>
            <Link className="page-link" to={pathBefore}>
              Anterior
            </Link>
          </li>
          <li className="d-flex overflow-auto ">
            <ul className="pagination">
              {areMorePagesBelowLowerLimit && (
                <li className={`page-item`}>
                  <Link className="page-link" to={pageStart}>
                    Inicio
                  </Link>
                </li>
              )}
              <ConatinerPages pageNumbers={pageNumbers} />
              {page < pages - pageLimit && (
                <li className={`page-item`}>
                  <Link className="page-link" to={pageEnd}>
                    Fin
                  </Link>
                </li>
              )}
            </ul>
          </li>
          <li className={`page-item ${page >= 1 ? '' : 'disabled'}`}>
            <Link className="page-link" to={pathNext}>
              Siguiente
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}
export default IntranetPagination
