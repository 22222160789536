import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import ItemList from '../../components/itemList/item'

import { favorites } from '../../api/lists'
import { userSelector } from '../../slices/user'
import { useSelector } from 'react-redux'

import { removeItemFav } from '../../api/products'
import { AiFillDelete } from 'react-icons/ai'

function useForceUpdate() {
  const [value, setValue] = useState(0)
  return () => setValue((value) => value + 1)
}

const Favorites = () => {
  const [saved, setSaved] = useState(null)
  const { isLogged } = useSelector(userSelector)

  const forceUpdate = useForceUpdate()

  useEffect(() => {
    async function handleFavorites() {
      const rs = await favorites()
      if (rs.status === 200) {
        setSaved(rs.data.productos)
      }
    }
    handleFavorites()
  }, [])

  const removeProductFav = async (item) => {
    if (isLogged) {
      try {
        const rs = await removeItemFav(item.url)
        if (rs.status && rs.status === 204) {
          for (var i = 0; i < saved.length; i++) {
            if (saved[i].id === item.id) {
              let deleteFromSaved = saved
              deleteFromSaved.splice(i, 1)
              setSaved(deleteFromSaved)
              forceUpdate()
            }
          }
        } else {
          console.log(rs)
          alert('Error de conexión. No se pudo eliminar de favoritos')
        }
      } catch (error) {
        console.log(error)
        alert('Error de conexión. No se pudo eliminar de favoritos')
      }
    }
  }

  return (
    <Container className="margin-top-md text-center">
      {saved === null ? (
        <h1 className="margin-top-md text-center"> Cargando... </h1>
      ) : saved.length > 0 ? (
        <Container className="margin-top-md text-center">
          <Row>
            <Col lg={12} className="margin-bottom-md">
              <h2>Mis guardadas</h2>
            </Col>
            {saved.map((item, i) => (
              <Col className="margin-md" key={i} lg={4}>
                <ItemList key={i} data={item} />
                <div className="center margin-top-xs ">
                  <Link
                    to="/favoritos"
                    className="button button--neutral button--small cart-item__delete "
                    aria-label="eliminar este item de favoritos"
                    onClick={() => removeProductFav(item)}
                  >
                    <AiFillDelete className="icon-sm" />
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        <div className="margin-top-md text-center">
          <h1>No has guardado ningún producto</h1>
        </div>
      )}
      {/* // {saved.length > 0 ? (
      //   <Row>
      //     <Col lg={12} className="margin-bottom-md">
      //       <h2>Mis guardadas</h2>
      //     </Col>
      //     {saved.map((item, i) => (
      //       <Col className="margin-md" key={i}>
      //         <ItemList key={i} data={item} />
      //         <div className="center margin-top-xs ">
      //           <Link
      //             to="/favoritos"
      //             className="button button--neutral button--small cart-item__delete "
      //             aria-label="eliminar este item de favoritos"
      //             onClick={() => removeProductFav(item)}
      //           >
      //             <AiFillDelete className="icon-sm" />
      //           </Link>
      //         </div>
      //       </Col>
      //     ))}
      //   </Row>
      // ) : (
      //   <div>
      //     <br />
      //     <br />
      //     <h1> No has guardado ningún producto </h1>
      //     <br />
      //     <br />
      //     <span
      //       className="divider-title"
      //       style={{
      //         display: 'block',
      //         margin: 'auto',
      //       }}
      //     ></span>
      //     <br />
      //     <br />
      //     <br />
      //     <br />
      //     <br />
      //     <br />
      //   </div>
      // )} */}
    </Container>
  )
}

export default Favorites
