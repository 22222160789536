const queryParamsToObject = (queryParams) => {
  let obj = {}
  const params = new URLSearchParams(queryParams)
  for (const [key, value] of params.entries()) {
    obj[key] = value
  }
  return obj
}

export const getCurrentPageUrlData = (queryParams, pageNumbers) => {
  let obj = queryParamsToObject(queryParams)

  if (obj.page) {
    const newObj = pageNumbers.map((pageNumber) => {
      const object = { ...obj, page: pageNumber }
      return object
    })
    return newObj
  } else {
    return obj
  }
}

export const createPagination = (iteration, upperPageLimit) => {
  const pageNumbers = []
  for (let i = iteration; i <= upperPageLimit; i++) {
    pageNumbers.push(iteration++)
  }
  return pageNumbers
}
